export const DESTINATIONS_URL = '/v1/destinations';

export const SESSION_URL = '/v1/session';
export const HOTEL_SESSION_URL = '/v1/session/hotel';
export const ACTIVITY_SESSION_URL = '/v1/session/activity';
export const BUS_SESSION_URL = '/v1/session/bus';
export const CURRENCY_SESSION_URL = '/v1/session/currency';

export const HOTEL_SEARCH_URL = '/v1/search/hotels';
export const HOTELS_FETCH_URL = '/v1/hotels';
export const ACTIVITIES_SEARCH_URL = '/v1/activities';
export const BUS_SEARCH_URL = '/v1/search/buses';

export const BOOKING_URL = '/v1/booking';
