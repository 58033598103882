
export const state = () => ({
  language: 'en'
});

export const mutations = {
  changeLanguage: (state, language) => (state.language = language)
};

export const actions = {
  changeLanguage: ({ commit }, language) => {
    localStorage.setItem('lang', language);
    commit('changeLanguage', language);
  }
};

export const getters = {
  getLanguage: state => state.language
};
