export default class DestinationsService {
  static fetchDestinations (axios, ipAddress) {
    return axios.$get('v1/destinations', { params: { ipAddress } });
  }

  static fetchAgents (axios, cityCode) {
    return axios.$get('v1/agents', { params: { cityCode } });
  }

  static fetchFaqs (axios, payload) {
    return axios.$get('v1/faqs', { params: payload });
  }
}
