export default function ({ store, redirect, $auth, $cookies }) {
  const isTripPlannerEnabled = store.getters['configCat/isTripPlannerEnabled'];
  const userAuthenticated = $auth.$state.user;
  const preferredLanguage = $cookies.get('lang');

  if (!isTripPlannerEnabled || !userAuthenticated) {
    const redirectPath = preferredLanguage === 'en' ? '/404' : '/fr/404';
    return redirect(redirectPath);
  }
}
