export default class VboutService {
  /**
   * Send payload (user data) to the VBout
   * @param {Object} payload
   */
  static async sendVboutPayload (axios, payload) {
    if (payload.language === 'en') {
      await axios.$post(
        `https://api.vbout.com/1/emailmarketing/addcontact?key=6865530531874006703622008&listid=77635&status=active&email=${payload.email}&firstname=${payload.given_name}&fields[492310]=${payload.family_name}&ipaddress=${payload.ipAddress}`
      );
    } else if (payload.language === 'fr') {
      await axios.$post(
        `https://api.vbout.com/1/emailmarketing/addcontact?key=6865530531874006703622008&listid=77636&status=active&email=${payload.email}&firstname=${payload.given_name}&fields[492310]=${payload.family_name}&ipaddress=${payload.ipAddress}`
      );
    }
  }
}
