
import { seoInfo } from '@/common/mixins/seoInfo';
export default {
  name: 'success',
  mixins: [seoInfo],
  computed: {
    lang () {
      return this.$i18n.locale || 'en';
    },
  },
  head () {
    return {
      title: this.lang === 'en' ? 'Success' : 'Succès',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
    };
  },
};
