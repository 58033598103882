import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=5ca1a321&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=5ca1a321&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca1a321",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/header/Header.vue').default})
