import { SearchModel } from '~/common/models/search.model';

export const state = () => ({
  search: SearchModel.getDefaultState(),
  actualSearch: null,
  startSearching: false,
});

export const mutations = {
  setSearch (state, search) {
    state.search = { ...state.search, ...search };
  },
  resetSearch (state) {
    state.search = SearchModel.getDefaultState();
  },
  startSearching (state) {
    state.startSearching = true;
  }
};

export const actions = {
  setSearch ({ commit }, search) {
    commit('setSearch', search);
  },
  resetSearch ({ commit }) {
    commit('resetSearch');
  },
  triggerSearching ({ commit }) {
    commit('startSearching');
  },
  restoreSearchByBus ({ commit, rootGetters }, bookedBus) {
    const { checkinDate, checkoutDate, city } = bookedBus;

    if (!city) {
      return;
    }

    const { code } = rootGetters['destination/getHotelDestinations'].find(({ name }) => city.toLowerCase() === name.toLowerCase());
    commit('setSearch', {
      ...SearchModel.getDefaultState(),
      cityCodeDeparture: code,
      dateStart: checkinDate.date,
      dateEnd: checkoutDate.date
    });
  },
};

export const getters = {
  search: state => state.search,
  cityCodeDeparture: state => state.search.cityCodeDeparture,
  cityCodeDestination: state => state.search.cityCodeDestination,
  dateStart: state => state.search.dateStart,
  dateEnd: state => state.search.dateEnd,
  startSearching: state => state.startSearching
};
