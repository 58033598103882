
import { mapGetters } from 'vuex';

export default {
  name: 'AlertsContainer',
  components: {
    AlertItem: () => import('~/components/alerts/AlertItem'),
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/alerts',
    }),
  },
  methods: {
    closeAlert (alertId) {
      this.$store.dispatch('alerts/closeAlert', alertId);
    },
  },
};
