import SessionService from '~/common/services/session.service';

export const actions = {
  async nuxtServerInit ({ dispatch, rootGetters }, { route, $axios }) {
    try {
      /**
       * Functionality for "product" pages only
       */
      if (route.name.includes('from_to')) {
        if (!rootGetters['session/getIpAddress']) {
          await dispatch('session/setIpAddress');
        }
        await dispatch(
          'destination/fetchDestinations',
          rootGetters['session/getIpAddress']
        ); // for the ItemsDropdown (e.g: passport nationality)
      }
      /**
       * Functionality for "without-footer" layout only
       * if this is not a home page
       */
      const pagesToExclude = [
        'index',
        'from_to',
        'hotelName',
        'Success',
        'privacy-policy',
        'terms-conditions',
        'about-us'
      ];
      if (!pagesToExclude.some(p => route.name.includes(p))) {
        if (!rootGetters['session/getIpAddress']) {
          await dispatch('session/setIpAddress');
        }
        dispatch(
          'destination/fetchDestinations',
          rootGetters['session/getIpAddress']
        ); // for the ItemsDropdown (e.g: passport nationality)

        if (route.name.includes('trip-planner')) {
          // for the Trip Planner only
          await dispatch(
            'configCat/fetchFeatureFlags',
            rootGetters['session/getIpAddress']
          );
        }

        const { query } = route;
        if (Object.keys(query).length) {
          dispatch('search/setSearch', {
            cityCodeDeparture: query.cityCodeDeparture,
            cityCodeDestination: query.cityCodeDestination,
            dateStart: query.dateStart,
            dateEnd: query.dateEnd,
          });
        }

        const sessionId = this.$cookies.get('sessionId') || query?.sessionId;
        this.$cookies.set('sessionId', sessionId);

        if (sessionId) {
          const session = await SessionService.fetchSession($axios, sessionId);
          dispatch('currency/changeCurrency', session.currency.code);
          dispatch('session/initSession', session);
          dispatch('passengers/initPassengers', {
            nbOfAdults: session.nbOfAdults,
            nbOfChildren: session.nbOfChildren,
            ageOfChildren: session.ageOfChildren,
          });
          dispatch('passengers/setAdultsInfo', session.passengers.adults);
          dispatch('passengers/setChildrenInfo', session.passengers.children);

          if (session.bookedBuses?.length) {
            dispatch('bus/init', session.bookedBuses);
          }
          if (session.bookedHotels?.length) {
            dispatch('hotel/init', session.bookedHotels[0]);
          }
          if (session.bookedActivities?.length) {
            dispatch('activity/init', session.bookedActivities);
          }
        }
      }
    } catch (e) {
      dispatch('alerts/showError', e?.response?.data.message);
    }
  },
};
