import ConfigCatService from '~/common/services/configCat.service';

export const state = () => ({
  featureFlags: {},
});

export const mutations = {
  setFeatureFlags (state, featureState) {
    state.featureFlags = featureState;
  }
};

export const actions = {
  async fetchFeatureFlags ({ commit }) {
    const ipAddress = this.$cookies.get('ipAddress') || '173.176.160.149';
    const data = await ConfigCatService.fetchFeatureFlags(this.$axios, ipAddress);
    commit('setFeatureFlags', data);
  }
};

export const getters = {
  featureFlags: state => state.featureFlags,
  directContractBusesOnTripezeEnabled: state => state.featureFlags.directContractBusesOnTripezeEnabled,
  displayMaintenancePage: state => state.featureFlags.displayMaintenancePage,
  isPrettyUpPriceForTripezeEnabled: state => state.featureFlags.isPrettyUpPriceForTripezeEnabled,
  isShortBusTimeoutEnabled: state => state.featureFlags.isShortBusTimeoutEnabled,
  isShortHotelTimeoutEnabled: state => state.featureFlags.isShortHotelTimeoutEnabled,
  isTripPlannerEnabled: state => state.featureFlags.isTripPlannerEnabled,
};
