export const state = () => ({
  isShow: false
});

export const mutations = {
  setSpinnerState (state, value) {
    state.isShow = value;
  }
};

export const actions = {
  showSpinner ({ commit }) {
    commit('setSpinnerState', true);
  },
  hideSpinner ({ commit }) {
    commit('setSpinnerState', false);
  }
};

export const getters = {
  isShow: state => state.isShow
};
