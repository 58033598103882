export const state = () => ({
  paymentInfo: {},
  isCancellationInsuranceAdded: false,
  cancellationInsuranceFee: 0
});

export const mutations = {
  setPaymentInfo (state, info) {
    state.paymentInfo = info;
  },
  resetPaymentInfo (state) {
    state.paymentInfo = {};
  },
  setCancellationInsuranceInfo (state, { isCancellationInsuranceAdded, cancellationInsuranceFee }) {
    state.isCancellationInsuranceAdded = isCancellationInsuranceAdded;
    state.cancellationInsuranceFee = cancellationInsuranceFee;
  }
};

export const actions = {
  setPaymentInfo ({ commit }, info) {
    commit('setPaymentInfo', info);
  },
  resetPaymentInfo ({ commit }) {
    commit('resetPaymentInfo');
  },
  setCancellationInsuranceInfo ({ commit }, info) {
    commit('setCancellationInsuranceInfo', info);
  }
};

export const getters = {
  getPaymentInfo: state => state.paymentInfo,
  getIsCancellationInsuranceAdded: state => state.isCancellationInsuranceAdded,
  getCancellationInsuranceFee: state => state.cancellationInsuranceFee
};
