export const ETicketBox = () => import('../../components/ETicketBox.vue' /* webpackChunkName: "components/e-ticket-box" */).then(c => wrapFunctional(c.default || c))
export const ActivitiesActivity = () => import('../../components/activities/Activity.vue' /* webpackChunkName: "components/activities-activity" */).then(c => wrapFunctional(c.default || c))
export const AlertsAlertItem = () => import('../../components/alerts/AlertItem.vue' /* webpackChunkName: "components/alerts-alert-item" */).then(c => wrapFunctional(c.default || c))
export const AlertsContainer = () => import('../../components/alerts/AlertsContainer.vue' /* webpackChunkName: "components/alerts-container" */).then(c => wrapFunctional(c.default || c))
export const BusTicket = () => import('../../components/bus/BusTicket.vue' /* webpackChunkName: "components/bus-ticket" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormsAdultForm = () => import('../../components/forms/AdultForm.vue' /* webpackChunkName: "components/forms-adult-form" */).then(c => wrapFunctional(c.default || c))
export const FormsChildrenForm = () => import('../../components/forms/ChildrenForm.vue' /* webpackChunkName: "components/forms-children-form" */).then(c => wrapFunctional(c.default || c))
export const FormsItemsDropdown = () => import('../../components/forms/ItemsDropdown.vue' /* webpackChunkName: "components/forms-items-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormsPaymentInfo = () => import('../../components/forms/PaymentInfo.vue' /* webpackChunkName: "components/forms-payment-info" */).then(c => wrapFunctional(c.default || c))
export const FormsUserInfo = () => import('../../components/forms/UserInfo.vue' /* webpackChunkName: "components/forms-user-info" */).then(c => wrapFunctional(c.default || c))
export const HeaderCurrencyDropdown = () => import('../../components/header/CurrencyDropdown.vue' /* webpackChunkName: "components/header-currency-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderLanguageDropdown = () => import('../../components/header/LanguageDropdown.vue' /* webpackChunkName: "components/header-language-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeatures = () => import('../../components/homepage/Features.vue' /* webpackChunkName: "components/homepage-features" */).then(c => wrapFunctional(c.default || c))
export const HomepageInfo = () => import('../../components/homepage/Info.vue' /* webpackChunkName: "components/homepage-info" */).then(c => wrapFunctional(c.default || c))
export const HomepageSubscription = () => import('../../components/homepage/Subscription.vue' /* webpackChunkName: "components/homepage-subscription" */).then(c => wrapFunctional(c.default || c))
export const HomepageTopPart = () => import('../../components/homepage/TopPart.vue' /* webpackChunkName: "components/homepage-top-part" */).then(c => wrapFunctional(c.default || c))
export const HotelCard = () => import('../../components/hotel/HotelCard.vue' /* webpackChunkName: "components/hotel-card" */).then(c => wrapFunctional(c.default || c))
export const ResultsHeader = () => import('../../components/results/ResultsHeader.vue' /* webpackChunkName: "components/results-header" */).then(c => wrapFunctional(c.default || c))
export const SearchCalendar = () => import('../../components/search/Calendar.vue' /* webpackChunkName: "components/search-calendar" */).then(c => wrapFunctional(c.default || c))
export const SearchResultsSearch = () => import('../../components/search/ResultsSearch.vue' /* webpackChunkName: "components/search-results-search" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/search/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchInfo = () => import('../../components/search/SearchInfo.vue' /* webpackChunkName: "components/search-info" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsConfirmationSkeleton = () => import('../../components/skeletons/ConfirmationSkeleton.vue' /* webpackChunkName: "components/skeletons-confirmation-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsPassengersSkeleton = () => import('../../components/skeletons/PassengersSkeleton.vue' /* webpackChunkName: "components/skeletons-passengers-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsPaymentSkeleton = () => import('../../components/skeletons/PaymentSkeleton.vue' /* webpackChunkName: "components/skeletons-payment-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsSearchSkeleton = () => import('../../components/skeletons/SearchSkeleton.vue' /* webpackChunkName: "components/skeletons-search-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsSummarySkeleton = () => import('../../components/skeletons/SummarySkeleton.vue' /* webpackChunkName: "components/skeletons-summary-skeleton" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerAddReservation = () => import('../../components/trip-planner/AddReservation.vue' /* webpackChunkName: "components/trip-planner-add-reservation" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerBudgeting = () => import('../../components/trip-planner/Budgeting.vue' /* webpackChunkName: "components/trip-planner-budgeting" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerCheckList = () => import('../../components/trip-planner/CheckList.vue' /* webpackChunkName: "components/trip-planner-check-list" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerNoComponents = () => import('../../components/trip-planner/NoComponents.vue' /* webpackChunkName: "components/trip-planner-no-components" */).then(c => wrapFunctional(c.default || c))
export const TripDetails = () => import('../../components/trip-planner/TripDetails.vue' /* webpackChunkName: "components/trip-details" */).then(c => wrapFunctional(c.default || c))
export const TripHelp = () => import('../../components/trip-planner/TripHelp.vue' /* webpackChunkName: "components/trip-help" */).then(c => wrapFunctional(c.default || c))
export const SearchDropdownsDaysDropdown = () => import('../../components/search/dropdowns/DaysDropdown.vue' /* webpackChunkName: "components/search-dropdowns-days-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SearchDropdownsLocationDropdown = () => import('../../components/search/dropdowns/LocationDropdown.vue' /* webpackChunkName: "components/search-dropdowns-location-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SearchDropdownsPassengers = () => import('../../components/search/dropdowns/Passengers.vue' /* webpackChunkName: "components/search-dropdowns-passengers" */).then(c => wrapFunctional(c.default || c))
export const SearchTabs = () => import('../../components/search/dropdowns/SearchTabs.vue' /* webpackChunkName: "components/search-tabs" */).then(c => wrapFunctional(c.default || c))
export const SearchDropdownsTripType = () => import('../../components/search/dropdowns/TripType.vue' /* webpackChunkName: "components/search-dropdowns-trip-type" */).then(c => wrapFunctional(c.default || c))
export const SearchTabsActivitySearch = () => import('../../components/search/tabs/ActivitySearch.vue' /* webpackChunkName: "components/search-tabs-activity-search" */).then(c => wrapFunctional(c.default || c))
export const SearchTabsBusAndHotelsSearch = () => import('../../components/search/tabs/BusAndHotelsSearch.vue' /* webpackChunkName: "components/search-tabs-bus-and-hotels-search" */).then(c => wrapFunctional(c.default || c))
export const SearchTabsBusSearch = () => import('../../components/search/tabs/BusSearch.vue' /* webpackChunkName: "components/search-tabs-bus-search" */).then(c => wrapFunctional(c.default || c))
export const SearchTabsHotelSearch = () => import('../../components/search/tabs/HotelSearch.vue' /* webpackChunkName: "components/search-tabs-hotel-search" */).then(c => wrapFunctional(c.default || c))
export const SearchTabsToursSearch = () => import('../../components/search/tabs/ToursSearch.vue' /* webpackChunkName: "components/search-tabs-tours-search" */).then(c => wrapFunctional(c.default || c))
export const TripActivity = () => import('../../components/trip-planner/cards/TripActivity.vue' /* webpackChunkName: "components/trip-activity" */).then(c => wrapFunctional(c.default || c))
export const TripBusTicket = () => import('../../components/trip-planner/cards/TripBusTicket.vue' /* webpackChunkName: "components/trip-bus-ticket" */).then(c => wrapFunctional(c.default || c))
export const TripHotelCard = () => import('../../components/trip-planner/cards/TripHotelCard.vue' /* webpackChunkName: "components/trip-hotel-card" */).then(c => wrapFunctional(c.default || c))
export const TripPackage = () => import('../../components/trip-planner/cards/TripPackage.vue' /* webpackChunkName: "components/trip-package" */).then(c => wrapFunctional(c.default || c))
export const TripRestaurant = () => import('../../components/trip-planner/cards/TripRestaurant.vue' /* webpackChunkName: "components/trip-restaurant" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsActivityForm = () => import('../../components/trip-planner/forms/ActivityForm.vue' /* webpackChunkName: "components/trip-planner-forms-activity-form" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsBusForm = () => import('../../components/trip-planner/forms/BusForm.vue' /* webpackChunkName: "components/trip-planner-forms-bus-form" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsHotelForm = () => import('../../components/trip-planner/forms/HotelForm.vue' /* webpackChunkName: "components/trip-planner-forms-hotel-form" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsRestaurantForm = () => import('../../components/trip-planner/forms/RestaurantForm.vue' /* webpackChunkName: "components/trip-planner-forms-restaurant-form" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsShareComponent = () => import('../../components/trip-planner/forms/ShareComponent.vue' /* webpackChunkName: "components/trip-planner-forms-share-component" */).then(c => wrapFunctional(c.default || c))
export const TripPlannerFormsSignUp = () => import('../../components/trip-planner/forms/SignUp.vue' /* webpackChunkName: "components/trip-planner-forms-sign-up" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
