
import { seoInfo } from '@/common/mixins/seoInfo';
export default {
  name: 'without-header',
  mixins: [seoInfo],
  computed: {
    lang () {
      return this.$i18n.locale || 'en';
    },
  },
  head () {
    return {
      title: this.getSEOTitle({ lang: this.lang }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.tripeze.com${this.$route.path}`,
        },
      ],
    };
  },
};
