export const TERM_ICON_MAPPING = {
  en: {
    carry: 'fa-bag-shopping',
    refunds: 'fa-ban',
    exchange: 'fa-arrow-right-arrow-left',
    checked: 'fa-suitcase-rolling',
    photo: 'fa-id-card',
    picture: 'fa-id-card',
    ticket: 'fa-ticket',
    passport: 'fa-passport'
  },
  fr: {
    carry: 'fa-bag-shopping',
    refunds: 'fa-ban',
    exchange: 'fa-arrow-right-arrow-left',
    checked: 'fa-suitcase-rolling',
    photo: 'fa-id-card',
    picture: 'fa-id-card',
    ticket: 'fa-ticket',
    passport: 'fa-passport'
  }
};
