
export const seoInfo = {
  methods: {
    /**
     * Return formatted date and allows to make shift by certain count of days
     * @param {string} productType
     * @param {string} departure
     * @param {string} destination
     * @param {string} lang
     * @returns {string}
     */
    getSEOTitle (payload) {
      if (payload.productType === 'bus-hotel') {
        if (payload.departure && payload.destination) {
          if (payload.lang === 'en') {
            return `Bus and hotel from ${payload.departure} to ${payload.destination} | Tripeze`;
          } else {
            return `Bus et hôtel de ${payload.departure} à ${payload.destination} | Tripeze`;
          }
        } else if (payload.departure) {
          if (payload.lang === 'en') {
            return `Bus and hotel in ${payload.departure} | Tripeze`;
          } else {
            return `Bus et hôtel à ${payload.departure} | Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book bus tickets and hotels in North America | Tripeze' : 'Réserver des billets de bus et des hôtels en Amérique du Nord | Tripeze';
        }
      } else if (payload.productType === 'hotels') {
        if (payload.departure) {
          if (payload.lang === 'en') {
            return `Book hotels in ${payload.departure} | Low rates with Tripeze`;
          } else {
            return `Hôtels à ${payload.departure} | Bas prix avec Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book hotels in North America | Tripeze' : 'Réserver des hôtels en Amérique du Nord | Tripeze';
        }
      } else if (payload.productType === 'bus') {
        if (payload.departure && payload.destination) {
          if (payload.lang === 'en') {
            return `Bus tickets from ${payload.departure} to ${payload.destination} | Tripeze`;
          } else {
            return `Bus de ${payload.departure} à ${payload.destination} | Tripeze`;
          }
        } else if (payload.departure) {
          if (payload.lang === 'en') {
            return `Bus tickets to ${payload.departure} | Low rates with Tripeze`;
          } else {
            return `Bus à ${payload.departure} | Bas prix avec Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book bus tickets in North America | Tripeze' : 'Réserver des billets de bus en Amérique du Nord | Tripeze';
        }
      } else {
        return payload.lang === 'en' ? 'Bus and hotels in Canada and United States | Tripeze' : 'Bus et hôtel au Canada et États-Unis | Tripeze';
      }
    },
    /**
     * Return formatted date and allows to make shift by certain count of days
     * @param {string} productType
     * @param {string} departure
     * @param {string} destination
     * @param {string} lang
     * @returns {string}
     */
    getSEODescription (payload) {
      if (payload.productType === 'bus-hotel') {
        if (payload.departure && payload.destination) {
          if (payload.lang === 'en') {
            return `Book bus and hotel from ${payload.departure} to ${payload.destination}. ${payload.companies?.length ? `Easily book bus tickets with${payload.companies.map(supplier => ' ' + supplier).toString()}` : 'Easily book bus tickets'} and hotels near bus stations. Book your bus trips with Tripeze`;
          } else {
            return `Réservez bus et hôtel de ${payload.departure} à ${payload.destination}. ${payload.companies?.length ? `Réservez facilement vos billets de bus avec${payload.companies.map(supplier => ' ' + supplier).toString()}` : 'Réservez facilement vos billets de bus'} avec un hôtel près de la gare d'autobus . Réservez vos voyages en bus avec Tripeze`;
          }
        } else if (payload.departure) {
          if (payload.lang === 'en') {
            return `Book bus and hotel in ${payload.departure}. ${payload.companies?.length ? `Easily book bus tickets with${payload.companies.map(supplier => ' ' + supplier).toString()}` : 'Easily book bus tickets'} and hotels near bus stations. Book your bus trips with Tripeze`;
          } else {
            return `Réservez bus et hôtel à ${payload.departure}. ${payload.companies?.length ? `Réservez facilement des billets de bus avec${payload.companies.map(supplier => ' ' + supplier).toString()}` : 'Réservez facilement des billets de bus'} et un hôtel près de la gare d'autobus . Réservez vos voyages en bus avec Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book bus tickets and hotels in Canada and the United States' : "Réservez des billets d'autobus et des hôtels au Canada et aux États-Unis";
        }
      } else if (payload.productType === 'hotels') {
        if (payload.departure) {
          if (payload.lang === 'en') {
            return `Book Hotels in ${payload.departure} with Tripeze for your trip.`;
          } else {
            return `Réservez des hôtels à ${payload.departure}. Bas prix sur les hôtels avec Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book hotels in Canada and the United States' : 'Réservez des hôtels au Canada et aux États-Unis';
        }
      } else if (payload.productType === 'bus') {
        if (payload.departure && payload.destination) {
          if (payload.lang === 'en') {
            return `Book bus tickets from ${payload.departure} to ${payload.destination}. ${payload.companies?.length ? `Easily book bus tickets with major bus lines such as${payload.companies.map(supplier => ' ' + supplier).toString()}.` : ''} Book your trip with Tripeze`;
          } else {
            return `Réservez vos billets de bus de ${payload.departure} à ${payload.destination}. ${payload.companies?.length ? `Réservez vos billets de bus avec des transporteurs tel que${payload.companies.map(supplier => ' ' + supplier).toString()}.` : ''} Réservez votre voyage en bus en avec Tripeze`;
          }
        } else if (payload.departure) {
          if (payload.lang === 'en') {
            return `Book bus tickets to ${payload.departure}. ${payload.companies?.length ? `Easily book bus tickets with${payload.companies.map(supplier => ' ' + supplier).toString()}` : ''}. Book your bus trips with Tripeze`;
          } else {
            return `Réservez vos billets de bus à ${payload.departure}. ${payload.companies?.length ? `Réservez vos billets de bus avec${payload.companies.map(supplier => ' ' + supplier).toString()}.` : ''} Réservez en vos voyages en bus avec Tripeze`;
          }
        } else {
          return payload.lang === 'en' ? 'Book bus tickets in Canada and the United States' : "Réservez des billets d'autobus au Canada et aux États-Unis";
        }
      } else {
        return payload.lang === 'en' ? 'Book bus and hotel packages in Canada and the United States' : "Réservez des billets d'autobus et hôtel au Canada et aux États-Unis";
      }
    },
    getSEOPageTitle (productType, departure, destination, lang) {
      if (productType === 'bus-hotel') {
        if (departure && destination) {
          if (lang === 'en') {
            return `Book bus tickets with hotels from ${departure} to ${destination}`;
          } else {
            return `Réservez des billets de bus avec hôtel de ${departure} à ${destination}`;
          }
        } else if (departure) {
          if (lang === 'en') {
            return `Easily book bus tickets with hotels to ${departure}`;
          } else {
            return `Réservez facilement des billets de bus avec hôtel à ${departure}`;
          }
        } else {
          return lang === 'en' ? 'Easily book bus trips across North America' : 'Réservez facilement des voyages en bus en Amérique du Nord';
        }
      } else if (productType === 'hotels') {
        if (departure) {
          if (lang === 'en') {
            return `Book Hotels in ${departure}`;
          } else {
            return `Réserver un hôtel à ${departure}`;
          }
        } else {
          return lang === 'en' ? 'Easily book bus trips across North America' : 'Réservez facilement des voyages en bus en Amérique du Nord';
        }
      } else if (productType === 'bus') {
        if (departure && destination) {
          if (lang === 'en') {
            return `Easily book bus tickets from ${departure} to ${destination}`;
          } else {
            return `Réservez facilement des billets de bus de ${departure} à ${destination}`;
          }
        } else if (departure) {
          if (lang === 'en') {
            return `Easily book bus tickets in ${departure}`;
          } else {
            return `Réservez facilement des billets de bus à ${departure}`;
          }
        } else {
          return lang === 'en' ? 'Easily book bus trips across North America' : 'Réservez facilement des voyages en bus en Amérique du Nord';
        }
      } else {
        return lang === 'en' ? 'Easily book bus trips across North America' : 'Réservez facilement des voyages en bus en Amérique du Nord';
      }
    },
    getSEOHotelTitle (cityName, hotelName, lang) {
      return lang === 'en' ? `${hotelName} in ${cityName} | Tripeze` : `${hotelName} à ${cityName} | Tripeze`;
    },
    getSEOHotelDescription (cityName, hotelName, lang) {
      return lang === 'en' ? `Book ${hotelName} in ${cityName} with Tripeze for your trip.` : `Réservez ${hotelName} à ${cityName} avec Tripeze pour votre voyage.`;
    },
    getSEOHotelPageTitle (cityName, hotelName, lang) {
      return lang === 'en' ? `${hotelName} in ${cityName}` : `${hotelName} à ${cityName}`;
    },
    getSEOHotelPageSubtitle (cityName, hotelName, lang) {
      return lang === 'en' ? `Book ${hotelName} in ${cityName} with Tripeze` : `Réservez ${hotelName} à ${cityName} avec Tripeze`;
    },
    getTwitterImageAlt (departure, destination, lang) {
      if (departure && destination) {
        if (lang === 'en') {
          return `Tripeze helps you book bus tickets and hotels from ${departure} to ${destination}`;
        } else {
          return `Tripeze vous aide à réserver des billets de bus et des hôtels de ${departure} à ${destination}`;
        }
      } else if (departure) {
        if (lang === 'en') {
          return `Tripeze helps you book bus tickets and hotels to ${departure}`;
        } else {
          return `Tripeze vous aide à réserver des billets de bus et des hôtels pour ${departure}`;
        }
      }
    }
  }
};
