
export const QUOTES_EN = [
  {
    quote: 'Create your own adventure',
    author: '~ Alain Pilon'
  },
  {
    quote: 'Not all those who wander are lost',
    author: '~ J.R.R. Tolkien'
  },
  {
    quote: 'The earth is what we all have in common',
    author: '~ Wendell Berry'
  },
  {
    quote: 'Life is either a daring adventure or nothing at all',
    author: '~ Helen Keller'
  },
  {
    quote: 'Take only memories, leave only footprints',
    author: '~ Chief Seattle'
  },
  {
    quote: 'The world is a book and those who do not travel read only one page.',
    author: '~ Saint Augustine'
  },
  {
    quote: 'Twenty years from now you will be more disappointed by the things you didn’t do than by the ones you did do',
    author: '~ H. Jackson Brown Jr'
  },
  {
    quote: 'Like all great travellers, I have seen more than I remember and remember more than I have seen.',
    author: '~ Benjamin Disraeli'
  },
  {
    quote: 'Traveling – it leaves you speechless, then turns you into a storyteller.',
    author: '~ Ibn Battuta'
  },
  {
    quote: 'If you are always trying to be normal, you will never know how amazing you can be.',
    author: '~ Maya Angelou'
  },
  {
    quote: 'Stop worrying about the potholes in the road and enjoy the journey',
    author: '~ Babs Hoffman'
  },
  {
    quote: 'Jobs fill your pocket but adventures fill your soul.',
    author: '~ Jamie Lyn Beatty'
  },
  {
    quote: 'Don’t worry about the world ending today, it’s already tomorrow in Australia.',
    author: '~ Charles M. Schulz'
  },
  {
    quote: 'Drink Heavily with locals whenever possible.',
    author: '~ Anthony Bourdain'
  },
  {
    quote: 'Travel is the only thing you buy that makes you richer.',
    author: '~ Said everyone'
  },
  {
    quote: 'It is better to see something once than to hear about it a thousand times.',
    author: '~ A person that is so right : )'
  },
  {
    quote: 'Live with no excuses and travel with no regrets',
    author: '~ Oscar Wilde'
  },
  {
    quote: 'Don’t Quit Your Day Dream',
    author: '~ A full-time dreamer'
  },
  {
    quote: 'Life’s a journey not a destination',
    author: '~ Aerosmith'
  },
  {
    quote: 'Those who follow the crowd usually get lost in it.',
    author: '~ Rick Watson'
  },
  {
    quote: 'Travel and you will find what you are looking for',
    author: '~ Vladyslav Polovyk'
  }
];

export const QUOTES_FR = [
  {
    quote: 'Créer votre propre aventure',
    author: '~ Alain Pilon'
  },
  {
    quote: 'La terre est ce que nous avons tous en commun',
    author: '~ Wendell Berry'
  },
  {
    quote: 'La vie est soit une aventure audacieuse, soit rien du tout',
    author: '~ Helen Keller'
  },
  {
    quote: 'Ne prends que des souvenirs, ne laisse que des empreintes',
    author: '~ Chief Seattle'
  },
  {
    quote: "Le monde est un livre et ceux qui ne voyagent pas n'en lisent qu'une page.",
    author: '~ Saint Augustine'
  },
  {
    quote: 'Un voyage de 1 000 kilomètres commence toujours par un pas',
    author: '~ Lao Tseu'
  },
  {
    quote: "S'éloigner de tout rapproche un peu de l'essentiel",
    author: '~ Loïck Peyron'
  },
  {
    quote: 'Voyager vous laisse d’abord sans voix, avant de vous transformer en conteur',
    author: '~ Ibn Battuta'
  },
  {
    quote: "Si vous essayez toujours d'être normal, vous ne saurez jamais à quel point vous pouvez être incroyable.",
    author: '~ Maya Angelou'
  },
  {
    quote: 'Arrêtez de vous soucier des nids-de-poule sur la route et profitez du voyage',
    author: '~ Babs Hoffman'
  },
  {
    quote: 'Les emplois remplissent votre poche mais les aventures remplissent votre âme.',
    author: '~ Jamie Lyn Beatty'
  },
  {
    quote: "Ne vous inquiétez pas de la fin du monde aujourd'hui, c'est déjà demain en Australie.",
    author: '~ Charles M. Schulz'
  },
  {
    quote: 'Buvez abondamment avec les locaux autant que possible.',
    author: '~ Anthony Bourdain'
  },
  {
    quote: 'Rester, c’est exister. Voyager, c’est vivre',
    author: '~ Gustave Nadaud'
  },
  {
    quote: 'Le voyage est la seule chose que vous achetez qui vous rend plus riche.',
    author: '~ Tout le monde'
  },
  {
    quote: "Il vaut mieux voir quelque chose une fois que d'en entendre parler mille fois.",
    author: '~ Une personne qui a tellement raison : )'
  },
  {
    quote: 'Vivre sans excuses et voyager sans regrets',
    author: '~ Oscar Wilde'
  },
  {
    quote: 'Ne quittez pas votre rêve de jour',
    author: '~ Un rêveur à temps plein'
  },
  {
    quote: 'La vie est un voyage, non une destination',
    author: '~Aerosmith'
  },
  {
    quote: 'Les voyages forment la jeunesse',
    author: '~ Montaigne'
  },
  {
    quote: 'Voyagez et vous trouverez ce que vous cherchez',
    author: '~ Vladyslav Polovyk'
  }
];
