import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aea7636a = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _75386bcc = () => interopDefault(import('../pages/activities/index.vue' /* webpackChunkName: "pages/activities/index" */))
const _59e0c4bf = () => interopDefault(import('../pages/Confirmation.vue' /* webpackChunkName: "pages/Confirmation" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e8164bba = () => interopDefault(import('../pages/Passengers.vue' /* webpackChunkName: "pages/Passengers" */))
const _dbee6c28 = () => interopDefault(import('../pages/Payment.vue' /* webpackChunkName: "pages/Payment" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _670fa11e = () => interopDefault(import('../pages/Receipt.vue' /* webpackChunkName: "pages/Receipt" */))
const _bb7edd2e = () => interopDefault(import('../pages/Success.vue' /* webpackChunkName: "pages/Success" */))
const _b28120e8 = () => interopDefault(import('../pages/Summary.vue' /* webpackChunkName: "pages/Summary" */))
const _a832b3aa = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _799add97 = () => interopDefault(import('../pages/trip-planner/index.vue' /* webpackChunkName: "pages/trip-planner/index" */))
const _3991ddc6 = () => interopDefault(import('../pages/activities/Options.vue' /* webpackChunkName: "pages/activities/Options" */))
const _080b2e23 = () => interopDefault(import('../pages/activities/Selected.vue' /* webpackChunkName: "pages/activities/Selected" */))
const _22206772 = () => interopDefault(import('../pages/auth/Callback.vue' /* webpackChunkName: "pages/auth/Callback" */))
const _6ba02b60 = () => interopDefault(import('../pages/bus-hotel/Accommodation.vue' /* webpackChunkName: "pages/bus-hotel/Accommodation" */))
const _36744d62 = () => interopDefault(import('../pages/bus-hotel/Outbound.vue' /* webpackChunkName: "pages/bus-hotel/Outbound" */))
const _4804ae22 = () => interopDefault(import('../pages/bus-hotel/Return.vue' /* webpackChunkName: "pages/bus-hotel/Return" */))
const _ca2abdae = () => interopDefault(import('../pages/bus/Outbound.vue' /* webpackChunkName: "pages/bus/Outbound" */))
const _808970ae = () => interopDefault(import('../pages/bus/Return.vue' /* webpackChunkName: "pages/bus/Return" */))
const _9bf994c6 = () => interopDefault(import('../pages/hotel/Accommodation.vue' /* webpackChunkName: "pages/hotel/Accommodation" */))
const _889ce9fc = () => interopDefault(import('../pages/activities/_id.vue' /* webpackChunkName: "pages/activities/_id" */))
const _ca827cc2 = () => interopDefault(import('../pages/hotel/_cityName/_hotelName.vue' /* webpackChunkName: "pages/hotel/_cityName/_hotelName" */))
const _0df920c4 = () => interopDefault(import('../pages/_from_to/index.vue' /* webpackChunkName: "pages/_from_to/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _aea7636a,
    name: "about-us___en"
  }, {
    path: "/activities",
    component: _75386bcc,
    name: "activities___en"
  }, {
    path: "/Confirmation",
    component: _59e0c4bf,
    name: "Confirmation___en"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance___en"
  }, {
    path: "/Passengers",
    component: _e8164bba,
    name: "Passengers___en"
  }, {
    path: "/Payment",
    component: _dbee6c28,
    name: "Payment___en"
  }, {
    path: "/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___en"
  }, {
    path: "/Receipt",
    component: _670fa11e,
    name: "Receipt___en"
  }, {
    path: "/Success",
    component: _bb7edd2e,
    name: "Success___en"
  }, {
    path: "/Summary",
    component: _b28120e8,
    name: "Summary___en"
  }, {
    path: "/terms-conditions",
    component: _a832b3aa,
    name: "terms-conditions___en"
  }, {
    path: "/trip-planner",
    component: _799add97,
    name: "trip-planner___en"
  }, {
    path: "/activities/Options",
    component: _3991ddc6,
    name: "activities-Options___en"
  }, {
    path: "/activities/Selected",
    component: _080b2e23,
    name: "activities-Selected___en"
  }, {
    path: "/auth/Callback",
    component: _22206772,
    name: "auth-Callback___en"
  }, {
    path: "/bus-hotel/Accommodation",
    component: _6ba02b60,
    name: "bus-hotel-Accommodation___en"
  }, {
    path: "/bus-hotel/Outbound",
    component: _36744d62,
    name: "bus-hotel-Outbound___en"
  }, {
    path: "/bus-hotel/Return",
    component: _4804ae22,
    name: "bus-hotel-Return___en"
  }, {
    path: "/bus/Outbound",
    component: _ca2abdae,
    name: "bus-Outbound___en"
  }, {
    path: "/bus/Return",
    component: _808970ae,
    name: "bus-Return___en"
  }, {
    path: "/fr/about-us",
    component: _aea7636a,
    name: "about-us___fr"
  }, {
    path: "/fr/activities",
    component: _75386bcc,
    name: "activities___fr"
  }, {
    path: "/fr/Confirmation",
    component: _59e0c4bf,
    name: "Confirmation___fr"
  }, {
    path: "/fr/maintenance",
    component: _4e16ad99,
    name: "maintenance___fr"
  }, {
    path: "/fr/Passengers",
    component: _e8164bba,
    name: "Passengers___fr"
  }, {
    path: "/fr/Payment",
    component: _dbee6c28,
    name: "Payment___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/Receipt",
    component: _670fa11e,
    name: "Receipt___fr"
  }, {
    path: "/fr/Success",
    component: _bb7edd2e,
    name: "Success___fr"
  }, {
    path: "/fr/Summary",
    component: _b28120e8,
    name: "Summary___fr"
  }, {
    path: "/fr/terms-conditions",
    component: _a832b3aa,
    name: "terms-conditions___fr"
  }, {
    path: "/fr/trip-planner",
    component: _799add97,
    name: "trip-planner___fr"
  }, {
    path: "/hotel/Accommodation",
    component: _9bf994c6,
    name: "hotel-Accommodation___en"
  }, {
    path: "/fr/activities/Options",
    component: _3991ddc6,
    name: "activities-Options___fr"
  }, {
    path: "/fr/activities/Selected",
    component: _080b2e23,
    name: "activities-Selected___fr"
  }, {
    path: "/fr/auth/Callback",
    component: _22206772,
    name: "auth-Callback___fr"
  }, {
    path: "/fr/bus-hotel/Accommodation",
    component: _6ba02b60,
    name: "bus-hotel-Accommodation___fr"
  }, {
    path: "/fr/bus-hotel/Outbound",
    component: _36744d62,
    name: "bus-hotel-Outbound___fr"
  }, {
    path: "/fr/bus-hotel/Return",
    component: _4804ae22,
    name: "bus-hotel-Return___fr"
  }, {
    path: "/fr/bus/Outbound",
    component: _ca2abdae,
    name: "bus-Outbound___fr"
  }, {
    path: "/fr/bus/Return",
    component: _808970ae,
    name: "bus-Return___fr"
  }, {
    path: "/fr/hotel/Accommodation",
    component: _9bf994c6,
    name: "hotel-Accommodation___fr"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/fr/activities/:id",
    component: _889ce9fc,
    name: "activities-id___fr"
  }, {
    path: "/fr/hotel/:cityName?/:hotelName?",
    component: _ca827cc2,
    name: "hotel-cityName-hotelName___fr"
  }, {
    path: "/activities/:id",
    component: _889ce9fc,
    name: "activities-id___en"
  }, {
    path: "/fr/:from_to",
    component: _0df920c4,
    name: "from_to___fr"
  }, {
    path: "/hotel/:cityName?/:hotelName?",
    component: _ca827cc2,
    name: "hotel-cityName-hotelName___en"
  }, {
    path: "/:from_to",
    component: _0df920c4,
    name: "from_to___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
