import ActivitiesService from '~/common/services/activities.service';

export const state = () => ({
  selectedActivities: [],
  availableActivities: []
});

export const mutations = {
  setSelectedActivities (state, activities) {
    state.selectedActivities = activities;
  },
  addSelectedActivity (state, activity) {
    state.selectedActivities = state.selectedActivities.concat(activity);
  },
  removeSelectedActivity (state, activityId) {
    state.selectedActivities = state.selectedActivities
      .filter(activity => activity.id !== activityId);
  },
  resetSelectedActivities (state) {
    state.selectedActivities = [];
  },
  setActivities (state, activities) {
    state.availableActivities = activities;
  },
  resetActivities (state) {
    state.availableActivities = [];
  }
};

export const actions = {
  init ({ commit }, activities) {
    commit('setSelectedActivities', activities);
  },
  selectActivity ({ commit }, activity) {
    commit('addSelectedActivity', activity);
    commit('selected/setLastSelected', 'Activities', { root: true });
  },
  removeActivity ({ commit }, activityId) {
    commit('removeSelectedActivity', activityId);
  },
  resetSelectedActivities ({ commit }) {
    commit('resetSelectedActivities');
  },
  async fetchActivities ({ commit, state }) {
    if (!state.availableActivities.length) {
      const activities = await ActivitiesService.fetchActivities(this.$axios);
      commit('setActivities', activities);
    }
  },
  resetActivities ({ commit }) {
    commit('resetActivities');
  }
};

export const getters = {
  activities: state => state.availableActivities,
  selectedActivities: state => state.selectedActivities
};
