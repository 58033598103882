
import { mapGetters, mapActions } from 'vuex';
import breakpointObserver from '~/common/mixins/breakpoint-observer';

export default {
  name: 'LanguageDropdown',
  mixins: [breakpointObserver],
  props: {
    isHeader: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    availableLanguages: [
      {
        code: 'en',
        name: 'English',
      },
      {
        code: 'fr',
        name: 'Français',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      getCurrentLanguage: 'language/getLanguage',
    }),
    currentLanguage () {
      return this.$i18n.locale || this.getCurrentLanguage;
    },
    currentLanguageText () {
      return this.availableLanguages.find(
        ({ code }) => this.currentLanguage === code
      ).name;
    },
    isHomePage () {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/fr' ||
        this.$route.path === '/en' ||
        this.$route.path.match('/maintenance') ||
        this.$route.path.match('^/bus$') ||
        this.$route.path.includes(this.$route.params.from_to) ||
        !!this.$route.params.hotelName
      );
    },
  },
  methods: {
    ...mapActions({
      changeLanguage: 'language/changeLanguage',
      fetchDestinations: 'destination/fetchDestinations',
      fetchNationalities: 'nationalities/fetchNationalities',
    }),
    changeLanguageMethod (lang) {
      this.changeLanguage(lang);
      this.$i18n.setLocale(lang);
      if (this.isHomePage) {
        setTimeout(() => {
          // give time for cookie changing
          location.reload(); // to change google widget on home page
        }, 100);
      }
      this.fetchDestinations();
      this.fetchNationalities();
    },
  },
};
