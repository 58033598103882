import { BOOKING_URL } from '@/common/constants';
export default class BookingService {
  static async processBooking (
    axios,
    ipAddress,
    passengers,
    expectedAmount,
    isCancellationInsuranceAdded,
    creditCard,
    captchaChallenge
  ) {
    return await axios.$post(BOOKING_URL, {
      ...passengers,
      ipAddress,
      expectedAmount,
      isCancellationInsuranceAdded,
      creditCard,
      captchaChallenge,
      captchaVersion: '3',
    });
  }

  static async bookingDetails (axios) {
    return await axios.$get(BOOKING_URL);
  }

  static savePageVisiting (axios, data) {
    axios
      .$post(
        'https://api.encharge.io/v1/hooks/c4c5f82f-3d74-4471-b1a4-09d7aec6fe1b',
        data
      )
      .catch();
  }

  static async downloadBookingReceipt (axios, { bookingNumber, receiptFormat }) {
    return await axios.get(
      `/v1/booking/${bookingNumber}/receipt/${receiptFormat}`, {
        responseType: 'blob',
      }
    );
  }
}
