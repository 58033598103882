export const AMENITIES = [
  'smallSeat',
  'averageSeat',
  'xlSeat',
  'fullReclineSeat',
  'refreshment',
  'food',
  'hotMeal',
  'trayMeal',
  'wifi',
  'individualTv',
  'individualSatelliteTv',
  'tv',
  'powerOutlets',
  'busAttendant',
  'ac',
  'toilet',
  'loungeAccess',
  'quietZone',
  'cafe',
  'restaurant',
  'checkedBaggage',
  'reservedSeats',
  'wheelchairRamp'
];

export const AMENITIES_ICONS = {
  smallSeat: 'fa-solid fa-person-seat',
  averageSeat: 'fa-solid fa-person-seat',
  xlSeat: 'fa-solid fa-person-seat',
  fullReclineSeat: 'fa-solid fa-person-seat-reclined',
  refreshment: 'fa-solid fa-whiskey-glass-ice',
  food: 'fa-solid fa-burger-soda',
  hotMeal: 'fa-solid fa-pot-food',
  trayMeal: 'fa-solid fa-pan-food',
  wifi: 'fa-solid fa-wifi',
  individualTv: 'fa-solid fa-tv',
  individualSatelliteTv: 'fa-solid fa-satellite-dish',
  tv: 'fa-solid fa-tv',
  powerOutlets: 'fa-solid fa-outlet',
  busAttendant: 'fa-solid fa-user-tie',
  ac: 'fa-solid fa-air-conditioner',
  toilet: 'fa-solid fa-toilet',
  loungeAccess: 'fa-solid fa-loveseat',
  quietZone: 'fa-solid fa-volume-slash',
  cafe: 'fa-solid fa-mug-saucer',
  restaurant: 'fa-solid fa-utensils',
  checkedBaggage: 'fa-solid fa-suitcase',
  reservedSeats: 'fa-solid fa-person-seat',
  wheelchairRamp: 'fa-solid fa-wheelchair'
};
