import HotelService from '~/common/services/hotel.service';
import SessionService from '~/common/services/session.service';

export const state = () => ({
  availableHotels: [],
  total: null,
  tryAgain: false,
  selectedHotel: null,
  selectedRoom: null,
  isDataFound: true,
  recommendedHotels: [],
  hotelsByCityCode: [],
  currentHotelProduct: null,
});

export const mutations = {
  setHotels (state, hotels) {
    state.availableHotels = hotels;
  },
  resetAvailableHotels (state) {
    state.availableHotels = [];
  },
  setMoreHotels (state, hotels) {
    state.availableHotels = state.availableHotels.concat(hotels);
  },
  setTotal (state, total) {
    state.total = total;
  },
  setTryAgain (state, tryAgain) {
    state.tryAgain = tryAgain;
  },
  setSelectedHotel (state, hotel) {
    state.selectedHotel = hotel;
  },
  resetSelectedHotel (state) {
    state.selectedHotel = null;
  },
  setSelectedRoom (state, room) {
    state.selectedRoom = room;
  },
  resetSelectedRoom (state) {
    state.selectedRoom = null;
  },
  setIsDataFound (state, isFound) {
    state.isDataFound = isFound;
  },
  setRecommendedHotels (state, hotels) {
    const recommendedHotels = [...state.recommendedHotels, ...hotels];
    state.recommendedHotels = [
      ...new Map(recommendedHotels.map(item => [item.name, item])).values(),
    ];
  },
  setHotelsByCityCode (state, hotels) {
    state.hotelsByCityCode = hotels;
  },
  setCurrentHotelProduct (state, hotel) {
    state.currentHotelProduct = hotel;
  },
};

export const actions = {
  init ({ commit }, hotel) {
    commit('setSelectedHotel', hotel);
    commit('setSelectedRoom', hotel.selectedRoom);
  },

  setSelectedHotel ({ commit, rootGetters }, hotel) {
    const { dateFrom, dateTo } = rootGetters['search/search'];
    commit('setSelectedHotel', {
      ...hotel,
      checkinDate: dateFrom,
      checkoutDate: dateTo,
    });
  },

  async resetSelectedHotel ({ commit, state }) {
    if (state.selectedHotel && state.selectedRoom) {
      if (this.$cookies.get('sessionId')) {
        const updatedSession = await SessionService.removeHotelFromSession(this.$axios, 0);
        commit('session/setSession', updatedSession, { root: true });
      }
      commit('resetSelectedRoom');
      commit('resetSelectedHotel');
    }
  },

  resetAvailableHotels ({ commit }) {
    commit('resetAvailableHotels');
  },

  async setSelectedRoom ({ commit, dispatch, state, rootGetters }, room) {
    const session = await SessionService.addHotel(
      this.$axios,
      state.selectedHotel.hotelIdentifier,
      room,
      rootGetters['search/dateStart'],
      rootGetters['search/dateEnd'],
      rootGetters['search/cityCodeDestination']
    );
    commit('setSelectedRoom', session?.bookedHotels?.[0]?.selectedRoom);
    commit('setSelectedHotel', session?.bookedHotels?.[0]);
    dispatch('selected/setLastSelected', 'Hotel', { root: true });
    await dispatch('session/fetchUpdatedSession', null, { root: true });
  },

  async fetchHotels ({ commit, rootGetters }, params) {
    commit('setIsDataFound', true); // remove the NoResults component, so if it appears again, it hides spinner
    const { data, total, tryAgain } = await HotelService.fetchHotels(
      this.$axios,
      {
        passengers: {
          nbOfAdults: rootGetters['passengers/countOfAdults'],
          nbOfChildren: rootGetters['passengers/countOfChildren'],
          ageOfChildren: rootGetters['passengers/ageOfChildren'],
        },
      },
      rootGetters['search/cityCodeDestination'],
      rootGetters['search/dateStart'],
      rootGetters['search/dateEnd'],
      params
    );
    commit(
      'setHotels',
      [...data].sort((a, b) =>
        a.cheapestRatePerNight > b.cheapestRatePerNight ? 1 : -1
      )
    );
    commit('setTotal', total);
    commit('setTryAgain', JSON.parse(tryAgain));
    commit('setIsDataFound', !!data.length);
  },

  async fetchMoreHotels ({ commit, rootGetters }, params) {
    const { data, total } = await HotelService.fetchHotels(
      this.$axios,
      {
        passengers: {
          nbOfAdults: rootGetters['passengers/countOfAdults'],
          nbOfChildren: rootGetters['passengers/countOfChildren'],
          ageOfChildren: rootGetters['passengers/ageOfChildren'],
          currencyCode: rootGetters['currency/currencyCode'],
        },
      },
      rootGetters['search/cityCodeDestination'],
      rootGetters['search/dateStart'],
      rootGetters['search/dateEnd'],
      params
    );
    commit('setMoreHotels', data);
    commit('setTotal', total);
  },

  async fetchAllHotelsByCityCode ({ commit }, cityCode) {
    const hotels = await HotelService.fetchAllHotelsByCityCode(this.$axios, cityCode);
    commit('setHotelsByCityCode', hotels);
  },

  async fetchRecommendedHotels ({ commit }, cityCode) {
    const hotels = await HotelService.fetchRecommendedHotels(this.$axios, cityCode);
    commit('setRecommendedHotels', hotels);
  },

  setCurrentHotelProduct ({ commit }, hotel) {
    commit('setCurrentHotelProduct', hotel);
  },
};

export const getters = {
  hotels: state => state.availableHotels,
  getHotelsTotal: state => state.total,
  isDataFound: state => state.isDataFound,
  tryAgain: state => state.tryAgain,
  selectedHotel: state => state.selectedHotel,
  selectedRoom: state => state.selectedRoom,
  getRecommendedHotels: state => state.recommendedHotels,
  getHotelsByCityCode: state => state.hotelsByCityCode,
  getCurrentHotelProduct: state => state.currentHotelProduct,
};
