
import { mapGetters } from 'vuex';
import CurrencyDropdown from '~/components/header/CurrencyDropdown';
import LanguageDropdown from '~/components/header/LanguageDropdown';
import breakpointObserver from '~/common/mixins/breakpoint-observer';

export default {
  name: 'Header',
  components: {
    CurrencyDropdown,
    LanguageDropdown,
    Icon: () => import('~/common/shared-components/Icon.vue'),
  },
  mixins: [breakpointObserver],
  computed: {
    ...mapGetters({
      isTripPlannerEnabled: 'configCat/isTripPlannerEnabled',
    }),
    user () {
      return this.$auth.loggedIn
        ? this.$auth.user
        : {
          picture: '',
          given_name: '',
          family_name: '',
        };
    },
    isHomePage () {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/fr' ||
        this.$route.path === '/en' ||
        this.$route.path.match('/maintenance') ||
        this.$route.path.match('^/bus$') ||
        this.$route.path.includes(this.$route.params.from_to) ||
        !!this.$route.params.hotelName
      );
    },
    headerClass () {
      return this.isHomePage ? 'header--transparent' : 'bg-white';
    },
  },
  methods: {
    toggleMenu () {
      document.body.classList.toggle('menu--opened');
    },
    goTo (path) {
      if (path === '/trip-planner' && !this.$auth.loggedIn) {
        // redirect to the trip-planner after logging in
        this.$router.push(this.localeLocation({ path }));
        this.$auth.loginWith('auth0');
        this.toggleMenu();
        return;
      }

      this.$router.push(this.localeLocation({ path }));
      this.toggleMenu();
    },
  },
};
