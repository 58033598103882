import {
  HOTEL_SEARCH_URL,
  HOTELS_FETCH_URL,
} from '@/common/constants';

export default class HotelService {
  /**
   * Retrieve hotels
   * @param {Object} passengers
   * @param {string} cityCode
   * @param {string} checkinDate
   * @param {string} checkoutDate
   * @return {Promise<HotelModel[]>}
   */
  static async fetchHotels (axios, passengers, cityCode, checkinDate, checkoutDate, params) {
    const { data, headers } = await axios.post(HOTEL_SEARCH_URL, {
      params,
      data: {
        ...passengers,
        cityCode,
        checkinDate,
        checkoutDate,
      }
    });
    return { data, total: Number(headers['x-total-count']), tryAgain: headers['x-try-again'] };
  }

  /**
   * Retrieve hotels
   * Axios plugin also supports shortcuts with $ prefixed methods to directly get data:
   * @param {string} cityCode
   */
  static async fetchAllHotelsByCityCode (axios, cityCode) {
    return await axios.$get(HOTELS_FETCH_URL, { params: { cityCode } });
  }

  /**
   * Retrieve hotels
   * @param {string} cityCode
   */
  static async fetchRecommendedHotels (axios, cityCode) {
    return await axios.$get(HOTELS_FETCH_URL, {
      params: { cityCode, isRecommend: true },
    });
  }
}
