export const ACTIVITIES = [
  {
    id: 'ACT-1',
    photo: 'https://1.bp.blogspot.com/-2Hkiozu8tYY/VrOB9ZgSFfI/AAAAAAAAGAk/f1KwtYvh3fE/w1200-h630-p-k-no-nu/KingKongEmpire-Inktober%25238.png',
    activityTime: 'Wednesday February 17, 2020 - can be use any time',
    price: '40.00',
    title: 'Empire State Bulding',
    address: '625 8th Ave, New York, NY 10018, USA',
    description: 'A beacon for international and domestic tourists alike, the Empire State Building offers unmatched views of New York and neighboring states from its world-famous 86th and 102nd floor observatories all year long!',
    preferableDates: [
      'August 15, 2020',
      'August 16, 2020',
      'August 17, 2020',
      'August 18, 2020'
    ],
    currency: {
      code: 'CAD',
      currencySymbol: '$'
    },
    availableOptions: [
      {
        id: 1,
        title: 'Lower Manhattan Walking Tour',
        subtitle: '',
        price: '$40.00'
      },
      {
        id: 2,
        title: 'Lower Manhattan Walking Tour',
        subtitle: 'with One World Observatory',
        price: '$60.00'
      },
      {
        id: 3,
        title: 'Lower Manhattan Walking Tour',
        subtitle: 'with 9/11 Memorial & Museum',
        price: '$80.00'
      }
    ]
  },
  {
    id: 'ACT-2',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRNbADqSUuMSBboMGpEqQrbec3nWjSE6Z11mw&usqp=CAU',
    activityTime: 'Wednesday February 17, 2020 - can be use any time',
    price: '56.00',
    title: 'Madame Tussauds',
    address: '625 8th Ave, New York, NY 10018, USA',
    description: 'A beacon for international and domestic tourists alike, the Empire State Building offers unmatched views of New York and neighboring states from its world-famous 86th and 102nd floor observatories all year long!',
    preferableDates: [
      'August 15, 2020',
      'August 16, 2020',
      'August 17, 2020',
      'August 18, 2020'
    ],
    currency: {
      code: 'CAD',
      currencySymbol: '$'
    },
    availableOptions: [
      {
        id: 1,
        title: 'Lower Manhattan Walking Tour',
        subtitle: '',
        price: '$40.00'
      },
      {
        id: 2,
        title: 'Lower Manhattan Walking Tour',
        subtitle: 'with One World Observatory',
        price: '$60.00'
      },
      {
        id: 3,
        title: 'Lower Manhattan Walking Tour',
        subtitle: 'with 9/11 Memorial & Museum',
        price: '$80.00'
      }
    ]
  }
];
