import { ACTIVITY_SESSION_URL, BUS_SESSION_URL, HOTEL_SESSION_URL, CURRENCY_SESSION_URL } from '@/common/constants';
export default class SessionService {
  /**
   * Retrieve saved session from the server
   *
   * @param axios
   * @return {Promise}
   */
  static fetchSession (axios) {
    return axios.$get('v1/session');
  }

  /**
   * Creating new user session
   *
   * @param axios
   * @param {number} nbOfAdults
   * @param {number} nbOfChildren
   * @param {number[]} ageOfChildren
   * @param {string} currencyCode
   * @return {Promise}
   */
  static createSession (
    axios,
    nbOfAdults,
    nbOfChildren,
    ageOfChildren,
    currencyCode
  ) {
    return axios.$post('v1/session', {
      nbOfAdults,
      nbOfChildren,
      ageOfChildren,
      currencyCode
    });
  }

  /**
   * Add selected hotel to session
   *
   * @param axios
   * @param {string} hotelIdentifier
   * @param {string} roomIdentifier
   * @param {string} checkinDate
   * @param {string} checkoutDate
   * @param {string} cityCode
   */
  static async addHotel (
    axios,
    hotelIdentifier,
    roomIdentifier,
    checkinDate,
    checkoutDate,
    cityCode
  ) {
    return await axios.$post(HOTEL_SESSION_URL, {
      hotelIdentifier,
      roomIdentifier,
      checkinDate,
      checkoutDate,
      cityCode
    });
  }

  /**
   * Remove selected hotel from session
   *
   * @param axios
   * @param {number} hotelIdentifier
   */
  static async removeHotelFromSession (
    axios,
    hotelIdentifier
  ) {
    return await axios.$delete(`${HOTEL_SESSION_URL}/${hotelIdentifier}`);
  }

  /**
   * @param axios
   * @param {Object} payload
   * @return {Promise<Session>}
   */
  static selectTicket (axios, payload) {
    return axios.$post(BUS_SESSION_URL, payload);
  }

  /**
   * Remove selected bus from session
   *
   * @param axios
   */
  static async removeBusFromSession (axios, busIdentifier) {
    return await axios.$delete(`${BUS_SESSION_URL}/${busIdentifier}`);
  }

  /**
   * Remove selected hotel from session
   *
   * @param axios
   * @param {string} activityIdentifier
   */
  static async removeActivityFromSession (
    axios,
    activityIdentifier
  ) {
    return await axios.$delete(`${ACTIVITY_SESSION_URL}/${activityIdentifier}`);
  }

  /**
   * Update session currency
   *
   * @param axios
   * @param {string} currencyCode
   * @returns
   */
  static async changeCurrency (axios, currencyCode) {
    return await axios.$patch(`${CURRENCY_SESSION_URL}/${currencyCode}`);
  }
}
