import { BUS_SEARCH_URL } from '~/common/constants';

export default class BusService {
  /**
   * Fetch outbound tickets
   * @param {Object} payload
   * @return {Promise<Bus[]>}
   */
  static async fetchOutboundTickets (axios, payload) {
    const { data, headers } = await axios.post(BUS_SEARCH_URL, payload);
    return { data, tryAgain: headers['x-try-again'] };
  }

  /**
   * Fetch return tickets
   * @return {Promise<Bus[]>}
   */
  static async fetchReturnTickets (axios, tripId) {
    const { data, headers } = await axios.post(`${BUS_SEARCH_URL}/return`, {
      tripId,
    });
    return { data, tryAgain: headers['x-try-again'] };
  }
}
