export const state = () => ({
  busHotelMode: false
});

export const mutations = {
  setMode (state, mode) {
    state.busHotelMode = mode;
  }
};

export const actions = {
  setMode ({ commit }, mode) {
    commit('setMode', mode);
  },
};

export const getters = {
  busHotelMode: state => state.busHotelMode,
};
