import { TRIP_TYPES } from '~/common/constants';

export class SearchModel {
  cityCodeDeparture = null
  cityCodeDestination = null
  tripType = TRIP_TYPES.ROUND_TRIP
  dateStart = null
  dateEnd = null

  static defaultState

  static getDefaultState () {
    if (!SearchModel.defaultState) {
      SearchModel.defaultState = new SearchModel();
    }
    return { ...SearchModel.defaultState };
  }
}
