
import { mapGetters, mapActions } from 'vuex';
import breakpointObserver from '~/common/mixins/breakpoint-observer';

export default {
  name: 'CurrencyDropdown',
  mixins: [breakpointObserver],
  data: () => ({
    availableCurrencies: [
      {
        code: 'CAD',
        text: 'CAD $',
      },
      {
        code: 'USD',
        text: 'USD $',
      },
      {
        code: 'EUR',
        text: 'EUR €',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      currentCurrency: 'currency/currencyCode',
    }),
    currentCurrencyText () {
      return this.availableCurrencies.find(
        ({ code }) => this.currentCurrency === code
      ).text;
    },
    isHomePage () {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/fr' ||
        this.$route.path === '/en' ||
        this.$route.path.match('/maintenance') ||
        this.$route.path.match('^/bus$') ||
        this.$route.path.includes(this.$route.params.from_to) ||
        !!this.$route.params.hotelName
      );
    },
  },
  methods: {
    ...mapActions({
      changeCurrency: 'currency/changeCurrency',
      showSpinner: 'spinner/showSpinner',
      resetSelectedHotel: 'hotel/resetSelectedHotel',
      resetAvailableHotels: 'hotel/resetAvailableHotels',
      fetchHotels: 'hotel/fetchHotels',
      resetSelected: 'bus/resetSelected',
      resetAvailableTickets: 'bus/resetAvailableTickets',
      fetchOutboundTickets: 'bus/fetchOutboundTickets',
    }),
    async change (code) {
      await this.changeCurrency(code);
      /**
       * change info of the hotel/ticket depending on the selected language
       */
      if (this.$route.path.includes('/hotel/')) {
        this.showSpinner();
        await Promise.all([
          this.resetSelectedHotel(),
          this.resetAvailableHotels(),
        ]);
        await this.fetchHotels();
      } else if (this.$route.path.includes('/bus/')) {
        this.showSpinner();
        await this.resetSelected();
        await this.fetchOutboundTickets();
        this.$router.push(
          this.localeLocation({
            path: '/bus/outbound',
            query: { ...this.$route.query },
          })
        );
      } else if (this.$route.path.includes('/bus-hotel/')) {
        this.showSpinner();
        // Reset data
        await Promise.all([
          this.resetSelectedHotel(),
          this.resetAvailableHotels(),
          this.resetSelected(),
          this.resetAvailableTickets(),
        ]);

        // Fetch hotels
        this.fetchHotels({
          currentPage: 1,
          perPage: 5,
          packageDepartureCityCode: this.$route.query.cityCodeDeparture
        });

        // Go to "accommodation" page
        this.$router.push(
          this.localeLocation({
            path: '/bus-hotel/accommodation',
            query: { ...this.$route.query },
          })
        );
      }
    },
  },
};
