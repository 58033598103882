export default {
  computed: {
    isMobile () {
      return this.$mq === 'mobile';
    },
    isDesktop () {
      return this.$mq === 'desktop';
    }
  }
};
