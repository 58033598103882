import moment from 'moment';
import { BUS_DIRECTIONS } from '~/common/constants';

export const OverviewHelper = {
  getHotelOverview (hotel, selectedRoom) {
    return {
      type: 'Hotel',
      hotelName: hotel.name,
      pricePerPerson: selectedRoom.ratePerNight
    };
  },
  /**
   * @param {Bus} ticket
   */
  getBusOverview (ticket) {
    return {
      type: ticket.direction === BUS_DIRECTIONS.OUTBOUND ? 'Outbound' : 'Return',
      pricePerPerson: ticket.price,
      date: moment.utc(ticket.departureTime).format('YYYY-MM-DD'),
      departureLocation: {
        city: ticket.departureLocation.name,
        time: ticket.departureTime,
        pore: moment(ticket.departureTime).format('A')
      },
      destinationLocation: {
        city: ticket.destinationLocation,
        time: ticket.arrivalTime,
        pore: moment(ticket.arrivalTime).format('A')
      }
    };
  },
  getActivityOverview (activity) {
    return {
      type: 'Activities',
      id: activity.id,
      activityName: activity.title,
      pricePerPerson: activity.price
    };
  }
};
