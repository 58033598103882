import BookingService from '~/common/services/booking.service';

export const state = () => ({
  bookingInfo: null,
});

export const mutations = {
  setBookingInfo: (state, bookingInfo) => (state.bookingInfo = bookingInfo),
  resetBookingInfo: state => (state.bookingInfo = null),
};

export const actions = {
  setBookingInfo ({ commit }, bookingInfo) {
    commit('setBookingInfo', bookingInfo);
  },
  async downloadBookingReceipt ({ commit }, { bookingNumber, receiptFormat }) {
    return await BookingService.downloadBookingReceipt(this.$axios, { bookingNumber, receiptFormat });
  }
};

export const getters = {
  bookingInfo: state => state.bookingInfo
};
