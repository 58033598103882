import NationalitiesService from '~/common/services/nationalities.service';

export const state = () => ({
  nationalities: []
});

export const mutations = {
  setNationalities: (state, nationalities) => {
    const canada = nationalities.find(n => n.name === 'Canada');
    const usa = nationalities.find(n => n.name === 'United States');

    state.nationalities = nationalities.filter(
      ({ name }) => name !== 'Canada' && name !== 'United States'
    );
    state.nationalities.unshift(canada, usa);
  },
  resetNationalities: state => (state.nationalities = [])
};

export const actions = {
  async fetchNationalities ({ commit }) {
    const nationalities = await NationalitiesService.fetchNationalities(this.$axios);
    commit('setNationalities', nationalities);
  }
};

export const getters = {
  nationalities: state => state.nationalities
};
