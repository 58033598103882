export const state = () => ({
  numberOfPassengers: {
    nbOfAdults: 2,
    nbOfChildren: 0,
    ageOfChildren: [],
  },
  adultsInfo: [],
  childrenInfo: [],
  textMarketing: null,
});

export const mutations = {
  setPassenger (state, passengers) {
    state.numberOfPassengers = passengers;
  },
  increaseCountOfAdults (state) {
    state.numberOfPassengers.nbOfAdults += 1;
    state.adultsInfo = [];
  },
  decreaseCountOfAdults (state) {
    state.numberOfPassengers.nbOfAdults -= 1;
    state.adultsInfo = [];
  },
  increaseCountOfChildren (state) {
    state.numberOfPassengers.nbOfChildren += 1;
    state.numberOfPassengers.ageOfChildren =
      state.numberOfPassengers.ageOfChildren.concat(5);
    state.childrenInfo = [];
  },
  decreaseCountOfChildren (state) {
    state.numberOfPassengers.nbOfChildren -= 1;
    state.numberOfPassengers.ageOfChildren =
      state.numberOfPassengers.nbOfChildren === 0
        ? []
        : state.numberOfPassengers.ageOfChildren.slice(
          0,
          state.numberOfPassengers.nbOfChildren
        );
    state.childrenInfo = [];
  },
  setAdultsInfo (state, adults) {
    state.adultsInfo = adults;
  },
  setChildrenInfo (state, children) {
    state.childrenInfo = children;
  },
  setAgeOfChildren (state, child) {
    state.numberOfPassengers.ageOfChildren[child.id - 1] = child.age;
  },
  setDateOfBirth (state, type) {
    const passengers =
      type === 'adults' ? state.adultsInfo : state.childrenInfo;

    passengers.forEach((passenger) => {
      if (typeof passenger.dateOfBirth === 'string') {
        const [year, month, day] = passenger.dateOfBirth.split('-');
        passenger.dateOfBirth = { day, month, year };
      } else {
        passenger.dateOfBirth = { day: '', month: '', year: '' };
      }
    });
  },
  setPassportExpiryDate (state, type) {
    const passengers =
      type === 'adults' ? state.adultsInfo : state.childrenInfo;

    passengers.forEach((passenger) => {
      if (typeof passenger.passportExpiryDate === 'string') {
        const [year, month, day] = passenger.passportExpiryDate.split('-');
        passenger.passportExpiryDate = { day, month, year };
      } else {
        passenger.passportExpiryDate = { day: '', month: '', year: '' };
      }
    });
  },
  resetPassengersInfo (state) {
    state.numberOfPassengers = {
      nbOfAdults: 2,
      nbOfChildren: 0,
      ageOfChildren: [],
    };
    state.adultsInfo = [];
    state.childrenInfo = [];
    state.textMarketing = null;
  },
  setTextMarketing (state, textMarketing) {
    state.textMarketing = textMarketing;
  },
  setNumberOfAdults (state, number) {
    state.numberOfPassengers.nbOfAdults = number;
  },
  setNumberOfChildren (state, number) {
    state.numberOfPassengers.nbOfChildren = number;
  },
};

export const actions = {
  initPassengers ({ commit }, passengers) {
    commit('setPassenger', passengers);
  },
  setNumberOfAdults ({ commit }, number) {
    commit('setNumberOfAdults', number);
  },
  increaseCountOfAdults ({ commit }) {
    commit('increaseCountOfAdults');
  },
  decreaseCountOfAdults ({ commit }) {
    commit('decreaseCountOfAdults');
  },
  setNumberOfChildren ({ commit }, number) {
    commit('setNumberOfChildren', number);
  },
  increaseCountOfChildren ({ commit }) {
    commit('increaseCountOfChildren');
  },
  decreaseCountOfChildren ({ commit }) {
    commit('decreaseCountOfChildren');
  },
  setAdultsInfo ({ commit }, adults) {
    commit('setAdultsInfo', adults);
  },
  setChildrenInfo ({ commit }, children) {
    commit('setChildrenInfo', children);
  },
  setAgeOfChildren ({ commit }, child) {
    commit('setAgeOfChildren', child);
  },
  setDateOfBirth ({ commit }, type) {
    commit('setDateOfBirth', type);
  },
  setPassportExpiryDate ({ commit }, type) {
    commit('setPassportExpiryDate', type);
  },
  resetPassengersInfo ({ commit }) {
    commit('resetPassengersInfo');
  },
  setTextMarketing ({ commit }, textMarketing) {
    commit('setTextMarketing', textMarketing);
  },
};

export const getters = {
  numberOfPassengers: state =>
    Number(state.numberOfPassengers.nbOfAdults) +
    Number(state.numberOfPassengers.nbOfChildren),
  adultsInfo: state => state.adultsInfo,
  childrenInfo: state => state.childrenInfo,
  ageOfChildren: state => state.numberOfPassengers.ageOfChildren,
  countOfAdults: state => state.numberOfPassengers.nbOfAdults,
  countOfChildren: state => state.numberOfPassengers.nbOfChildren,
  textMarketing: state => state.textMarketing,
};
