import SessionService from '~/common/services/session.service';

export const state = () => ({
  session: {},
  sessionId: null,
  ipAddress: '',
});

export const mutations = {
  setSession (state, session) {
    state.session = session;
  },
  setSessionId (state, sessionId) {
    state.sessionId = sessionId;
  },
  resetSession (state) {
    state.session = state.sessionId = null;
  },
  setIpAddress (state, ip) {
    state.ipAddress = ip;
  }
};

export const actions = {
  initSession ({ commit }, session) {
    commit('setSession', session);
    commit('setSessionId', session.sessionId);
  },
  async createSession ({ commit, dispatch, rootGetters }) {
    const countOfAdults = rootGetters['passengers/countOfAdults'];
    const countOfChildren = rootGetters['passengers/countOfChildren'];
    const ageOfChildren = rootGetters['passengers/ageOfChildren'];
    const currencyCode = rootGetters['currency/currencyCode'];

    const session = await SessionService.createSession(
      this.$axios,
      countOfAdults,
      countOfChildren,
      ageOfChildren,
      currencyCode
    );
    dispatch('setSessionId', session.sessionId);
    commit('setSession', session);
  },
  async fetchUpdatedSession ({ commit, dispatch }) {
    const session = await SessionService.fetchSession(this.$axios);
    dispatch('setSessionId', session.sessionId);
    commit('setSession', session);
  },
  setSessionId ({ commit }, sessionId) {
    commit('setSessionId', sessionId);
    this.$cookies.set('sessionId', sessionId);
  },
  updateSession ({ commit }, session) {
    commit('setSession', session);
  },
  resetSession ({ commit }) {
    commit('resetSession');
    this.$cookies.remove('sessionId');
  },
  async setIpAddress ({ commit }) {
    let ip = this.$cookies.get('ipAddress');
    if (!ip) {
      ip = await this.$axios.$get('https://api.ipify.org'); // '173.176.160.149'
      // Set expiration time for the cookie (7 days from now)
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);

      this.$cookies.set('ipAddress', ip, {
        expires: expirationDate
      });
    }
    commit('setIpAddress', ip);
  },
  async changeCurrency ({ commit, dispatch }, currencyCode) {
    const session = await SessionService.changeCurrency(this.$axios, currencyCode);
    dispatch('setSessionId', session.sessionId);
    commit('setSession', session);
  },
};

export const getters = {
  session: state => state.session,
  sessionId: state => state.sessionId,
  getIpAddress: state => state.ipAddress,
  finalBill: state => state.session && ({
    total: state.session.total,
    subtotal: state.session.subtotal,
    taxBreakdown: state.session.taxBreakdown
  }),
};
