import { OverviewHelper } from '~/common/helpers/overview-helper';

export const state = () => ({
  lastSelected: null,
});

export const mutations = {
  setLastSelected (state, item) {
    state.lastSelected = item;
  },
};

export const actions = {
  setLastSelected ({ commit }, item) {
    commit('setLastSelected', item);
  },
};

export const getters = {
  selectedBusses: (state, getters, rootState, rootGetters) => {
    return rootGetters['bus/selectedTickets'].map(
      OverviewHelper.getBusOverview
    );
  },
  selectedHotel: (state, getters, rootState, rootGetters) => {
    const selectedRoom = rootGetters['hotel/selectedRoom'];
    const selectedHotel = rootGetters['hotel/selectedHotel'];

    if (selectedRoom && selectedHotel) {
      return OverviewHelper.getHotelOverview(selectedHotel, selectedRoom);
    }

    return null;
  },
  selectedActivities: (state, getters, rootState, rootGetters) => {
    return rootGetters['activity/selectedActivities'].map(
      OverviewHelper.getActivityOverview
    );
  },
  selectedItems: (state, getters) => {
    return [
      ...getters.selectedBusses,
      getters.selectedHotel,
      ...getters.selectedActivities,
    ].filter(item => item);
  },
  lastSelected: (state, getters) => {
    if (state.lastSelected) {
      return state.lastSelected;
    }
    const lastSelected =
      getters.selectedItems[getters.selectedItems.length - 1];
    return lastSelected ? lastSelected.type : '';
  },
};
