import BusService from '~/common/services/bus.service';
import SessionService from '~/common/services/session.service';
import { TRIP_TYPES } from '~/common/constants';

export const state = () => ({
  outbound: null,
  return: null,
  availableOutboundTickets: [],
  availableReturnTickets: [],
  isDataFound: true,
  tryAgain: false,
});

export const mutations = {
  setOutbound (state, ticket) {
    state.outbound = {
      ...ticket,
      isReturn: false,
    };
  },
  setReturn (state, ticket) {
    state.return = {
      ...ticket,
      isReturn: true,
    };
  },
  setOutboundTickets (state, tickets) {
    state.availableOutboundTickets = tickets;
  },
  setReturnTickets (state, tickets) {
    state.availableReturnTickets = tickets;
  },
  resetSelected (state) {
    state.outbound = null;
    state.return = null;
  },
  resetAvailableTickets (state) {
    state.availableOutboundTickets = [];
    state.availableReturnTickets = [];
  },
  resetAvailableReturnTickets (state) {
    state.availableReturnTickets = [];
  },
  setIsDataFound (state, isFound) {
    state.isDataFound = isFound;
  },
  setTryAgain (state, tryAgain) {
    state.tryAgain = tryAgain;
  },
};

export const actions = {
  init ({ commit }, busses) {
    commit('setOutbound', busses[0].outbound);
    if (busses[0].return) {
      commit('setReturn', busses[0].return);
    }
  },

  async selectTicket ({ commit, dispatch }, ticket) {
    const isReturn = ticket.direction === 'return';
    if (ticket.type === TRIP_TYPES.ONE_WAY || isReturn) {
      const updatedSession = await SessionService.selectTicket(
        this.$axios,
        {
          tripId: ticket.tripId,
        }
      );

      commit('session/setSession', updatedSession, { root: true });
    }
    dispatch('selected/setLastSelected', isReturn ? 'Return' : 'Outbound', { root: true });
    commit(isReturn ? 'setReturn' : 'setOutbound', ticket);
  },

  resetAvailableTickets ({ commit }) {
    commit('resetAvailableTickets');
  },

  resetAvailableReturnTickets ({ commit }) {
    commit('resetAvailableReturnTickets');
  },

  resetSelected ({ commit }) {
    commit('resetAvailableReturnTickets');
    commit('resetSelected');
  },

  async reSelectTickets ({ commit, rootGetters }) {
    const session = rootGetters['session/session'];
    if (session.bookedBuses && session.bookedBuses[0]) {
      const updatedSession = await SessionService.removeBusFromSession(this.$axios, 0);
      commit('session/setSession', updatedSession, { root: true });
    }
  },

  async fetchOutboundTickets ({ commit, rootGetters }) {
    const search = rootGetters['search/search'];
    const isOneWay = search.tripType === 'oneWay';
    const dateStart = rootGetters['search/dateStart'];
    const dateEnd = isOneWay ? undefined : rootGetters['search/dateEnd'];
    const adultsCount = rootGetters['passengers/countOfAdults'];
    const childrenCount = rootGetters['passengers/countOfChildren'];
    const ageOfChildren = rootGetters['passengers/ageOfChildren'];
    const departureCityCode = rootGetters['search/cityCodeDeparture'];
    const destinationCityCode = rootGetters['search/cityCodeDestination'];
    const busHotelMode = rootGetters['bus-hotel/busHotelMode'];

    // Remove the NoResults component, so if it appears again, it hides spinner
    commit('setIsDataFound', true);

    try {
      let tickets = [];
      let shouldTryAgain = false;

      do {
        const { data, tryAgain } = await BusService.fetchOutboundTickets(this.$axios, {
          passengers: {
            nbOfAdults: adultsCount,
            nbOfChildren: childrenCount,
            ageOfChildren,
          },
          departureCityCode,
          destinationCityCode,
          acceptTripsArrivingTheDayOf: busHotelMode,
          date: dateStart,
          returnDate: dateEnd,
        });

        tickets = data;
        shouldTryAgain = JSON.parse(tryAgain);
      } while (!tickets.every(({ departureId }) => departureId !== 'MOCKED_DEPARTURE_ID'));

      commit('setTryAgain', shouldTryAgain);
      commit('setOutboundTickets', tickets);
      commit('setIsDataFound', !!tickets.length);
    } catch (e) {
      commit('setOutboundTickets', []);
    }
  },

  async fetchReturnTickets ({ commit, getters }) {
    try {
      const { data: tickets, tryAgain } = await BusService.fetchReturnTickets(
        this.$axios,
        getters.outbound.tripId,
      );
      commit('setTryAgain', JSON.parse(tryAgain));
      commit('setReturnTickets', tickets);
      commit('setIsDataFound', !!tickets.length);
    } catch (e) {
      commit('setReturnTickets', []);
    }
  },
};

export const getters = {
  outbound: state => state.outbound,
  return: state => state.return,
  availableOutboundTickets: state => state.availableOutboundTickets,
  availableReturnTickets: state => state.availableReturnTickets,
  selectedTickets: state =>
    [state.outbound, state.return].filter(ticket => ticket),
  isDataFound: state => state.isDataFound,
  tryAgain: state => state.tryAgain,
};
