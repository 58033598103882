
import { seoInfo } from '@/common/mixins/seoInfo';
export default {
  name: 'error.vue',
  mixins: [seoInfo],
  computed: {
    lang () {
      return this.$i18n.locale || 'en';
    },
  },
  head () {
    return {
      title: 404,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
    };
  },
};
