export default function ({ store, redirect }) {
  const selectedItems = store.getters['selected/selectedItems'];
  const isSessionLocked = store.getters['session/session'].isLocked;

  if (!selectedItems.length || isSessionLocked) {
    return redirect('/404');
  }

  return true;
}
