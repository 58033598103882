import { uuid } from 'vue-uuid';

export const state = () => ({
  alerts: [],
});

export const mutations = {
  addAlert (state, alert) {
    state.alerts = state.alerts.concat(alert);
  },
  removeAlert (state, alertId) {
    state.alerts = state.alerts.filter(({ id }) => id !== alertId);
  },
};

export const actions = {
  showError ({ commit }, msg) {
    if (!msg) {
      return;
    }

    commit('addAlert', {
      id: uuid.v1(),
      type: 'danger',
      msg,
    });
  },
  showSuccess ({ commit }, msg) {
    if (!msg) {
      return;
    }

    commit('addAlert', {
      id: uuid.v1(),
      type: 'success',
      msg,
    });
  },
  closeAlert ({ commit }, alertId) {
    commit('removeAlert', alertId);
  },
};

export const getters = {
  alerts: state => state.alerts
};
