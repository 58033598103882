import DestinationsService from '~/common/services/destinations.service';

export const state = () => ({
  destinations: [],
  currentDeparture: null,
  currentDestination: null,
  agents: [],
  faqs: [],
});

export const mutations = {
  setDestinations: (state, destinations) =>
    (state.destinations = destinations.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    })),
  resetDestinations: state => (state.destinations = []),
  setCurrentDeparture: (state, departure) =>
    (state.currentDeparture = departure),
  setCurrentDestination: (state, destination) =>
    (state.currentDestination = destination),
  setAgents: (state, agents) => (state.agents = agents),
  setFaqs: (state, faqs) => (state.faqs = faqs),
};

export const actions = {
  async fetchDestinations ({ commit }, ipAddress) {
    const destinations = await DestinationsService.fetchDestinations(
      this.$axios,
      ipAddress
    );
    commit('setDestinations', destinations);
  },
  setCurrentDeparture ({ commit }, departure) {
    commit('setCurrentDeparture', departure);
  },
  setCurrentDestination ({ commit }, destination) {
    commit('setCurrentDestination', destination);
  },
  async fetchAgents ({ commit }, cityCode) {
    const agents = await DestinationsService.fetchAgents(this.$axios, cityCode);
    commit('setAgents', agents);
  },
  async fetchFaqs ({ commit }, payload) {
    const agents = await DestinationsService.fetchFaqs(this.$axios, payload);
    commit('setFaqs', agents);
  },
};

export const getters = {
  destinations: state => state.destinations,
  getAgents: state => state.agents,
  getFaqs: state => state.faqs,
  getCompanies: state =>
    state.currentDestination
      ? state.currentDeparture.busDestinations
        .find(dest => dest.code === state.currentDestination.code)
        ?.busCompanies.map(company => company.name)
      : state.currentDeparture
        ? [
          ...new Set(
            state.currentDeparture.busDestinations.map((dest) => {
              return dest?.busCompanies
                .map(company => company.name)
                .toString();
            })
          ),
        ]
        : [],
  getCurrentDeparture: state => state.currentDeparture,
  getCurrentDestination: state => state.currentDestination,
  getBusDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(destination =>
        destination.experiences.includes('buses')
      )
      : [],
  getNearMeBusDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(
        destination =>
          destination.experiences.includes('buses') && destination.nearMe
      )
      : [],
  getHotelDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(destination =>
        destination.experiences.includes('hotels')
      )
      : [],
  getNearMeHotelDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(
        destination =>
          destination.experiences.includes('hotels') && destination.nearMe
      )
      : [],
  getBusAndHotelDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(
        destination =>
          destination.experiences.includes('buses') &&
          destination.experiences.includes('hotels')
      )
      : [],
  getNearMeBusAndHotelDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(
        destination =>
          destination.experiences.includes('buses') &&
          destination.experiences.includes('hotels') &&
          destination.nearMe
      )
      : [],
  getActivitiesDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(destination =>
        destination.experiences.includes('activities')
      )
      : [],
  getNearMeActivitiesDestinations: state =>
    state.destinations.length
      ? state.destinations.filter(
        destination =>
          destination.experiences.includes('activities') && destination.nearMe
      )
      : [],
};
