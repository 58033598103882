export default function ({ route, error, redirect }) {
  /**
   * RegExr:
   * 1) (bus-hotel|bus|hotels) - Groups multiple tokens together and creates a capture group for extracting a substring or using a backreference.
   * 2) "-" - matches a '-' character
   * 3) \D - Matches any character that is not a digit character (0-9). Equivalent to [^0-9].
   * 4) "+" - Matches 1 or more of the preceding token.
   * 5) [^ ] - Match any character that is not in the set.
   * 6) | - acts like a boolean OR.
   * 7) (bus|hotels) - (1) (e.g: ".../bus", ".../hotels" - without departure and destination)
   */
  const validURL = route.params.from_to.match(/((bus-hotel|bus|hotels)-\D+[^-])|(bus|hotels)/g);
  if (route.params.from_to === 'bus-hotel') {
    redirect('/bus');
  }
  if (!validURL) {
    error({ statusCode: 404, message: 'Not Found' });
  }
  return true;
}
