
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { seoInfo } from '@/common/mixins/seoInfo';
import { mapGetters, mapActions } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { QUOTES_EN, QUOTES_FR } from '~/common/constants';
import VboutService from '~/common/services/vbout.service';
import Header from '~/components/header/Header';

export default {
  name: 'default',
  components: {
    LazyHydrate,
    Header,
    Footer: () => import('~/components/footer/Footer'),
    AlertsContainer: () => import('~/components/alerts/AlertsContainer'),
  },
  mixins: [seoInfo],
  async fetch () {
    this.restoreAuth();
    await this.fetchFeatureFlags();
  },
  data: () => ({
    quotes: [...QUOTES_EN],
    indexOfQuote: Math.floor(Math.random() * 10),
    address: '',
  }),
  computed: {
    ...mapGetters({
      ipAddress: 'session/getIpAddress',
      isShowSpinner: 'spinner/isShow',
      toastConfig: 'systemMessage/toastConfig',
      destinations: 'destination/destinations',
      getNearMeBusDestinations: 'destination/getNearMeBusDestinations',
      displayMaintenancePage: 'configCat/displayMaintenancePage'
    }),
    lang () {
      return this.$i18n.locale;
    },
  },
  watch: {
    toastConfig () {
      if (this.toastConfig.length) {
        const current = this.toastConfig[0];
        this.$bvToast.toast(current.message, {
          solid: true,
          variant: current.variant,
          title: current.title,
        });
      }
    },
    isShowSpinner () {
      if (this.isShowSpinner) {
        this.indexOfQuote = Math.floor(Math.random() * this.quotes.length);
      }
    },
  },
  created () {
    // set language depending on the url
    if (process.client) {
      if (this.$route.path.includes('fr')) {
        this.changeLanguage('fr');
      } else {
        this.changeLanguage('en');
      }
    }
  },
  mounted () {
    /**
     * Checking displayMaintenancePage feature flag
     */
    if (this.displayMaintenancePage) {
      this.$router.push(
        this.localeLocation('/maintenance')
      );
      return;
    }
    // fetch all required data
    this.fetchRequiredData();
  },
  methods: {
    ...mapActions({
      setIpAddress: 'session/setIpAddress',
      changeLanguage: 'language/changeLanguage',
      fetchDestinations: 'destination/fetchDestinations',
      changeCurrency: 'currency/changeCurrency',
      showSpinner: 'spinner/showSpinner',
      hideSpinner: 'spinner/hideSpinner',
      fetchFeatureFlags: 'configCat/fetchFeatureFlags',
    }),
    async fetchRequiredData () {
      // e.g: after page refreshing
      if (!this.ipAddress) {
        await this.setIpAddress();
      }
      // can be fetched already on the "product" pages
      if (!this.destinations.length) {
        this.fetchDestinations(this.ipAddress);
      }
      this.displayLocationCurrency();
      /**
       * Show Jivo Widget
       */
      if (this.lang === 'en') {
        this.widget_EN();
        this.quotes = [...QUOTES_EN];
      } else {
        this.widget_FR();
        this.quotes = [...QUOTES_FR];
      }
      /** */
    },
    async restoreAuth () {
      const token = this.$cookies.get('auth._token.auth0');
      if (!this.$auth.loggedIn && token) {
        // if we signed-in using google-one-tap
        const oldRedirect = this.$auth.options.redirect;
        this.$auth.options.redirect = false;
        const tokenPayload = jwt_decode(token);
        if (tokenPayload.email) {
          await this.$auth.setUser(tokenPayload);
        }
        this.$auth.options.rewriteRedirects = oldRedirect;
      }
      /**
       * send user's data to the Vbout
       */
      if (this.$auth.loggedIn && this.$auth.user) {
        VboutService.sendVboutPayload(this.$axios, {
          language: this.lang,
          email: this.$auth.user.email,
          given_name: this.$auth.user.given_name,
          family_name: this.$auth.user.family_name,
          ipAddress: this.ipAddress,
        });
      }
    },
    widget_FR () {
      const widgetScript = document.createElement('script');
      widgetScript.setAttribute(
        'src',
        '//code-eu1.jivosite.com/widget/Ge80Yp19xG'
      );
      widgetScript.async = true;
      document.body.appendChild(widgetScript);
    },
    widget_EN () {
      const widgetScript = document.createElement('script');
      widgetScript.setAttribute(
        'src',
        '//code-eu1.jivosite.com/widget/zx4Ga0cxPk'
      );
      widgetScript.async = true;
      document.head.appendChild(widgetScript);
    },
    // Doc: https://ipapi.co/api/#introduction
    // Free: up-to 1000 requests per day
    async displayLocationCurrency () {
      try {
        const { data } = await this.$axios.get(
          `https://ipapi.co/${this.ipAddress}/json`
        );
        if (data.country_name === 'Canada') {
          this.changeCurrency('CAD');
        } else if (data.timezone.includes('Europe')) {
          this.changeCurrency('EUR');
        } else {
          this.changeCurrency('USD');
        }
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  head () {
    return {
      title: this.getSEOTitle({ lang: this.lang }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
    };
  },
};
