export const state = () => ({
  toastConfig: []
});

export const getters = {
  toastConfig: state => state.toastConfig
};

export const mutations = {
  setToastConfig (state, config) {
    state.toastConfig = [config, ...state.toastConfig];
  }
};

export const actions = {
  showToast ({ commit }, { message = '', title = '', variant = 'primary' }) {
    commit('setToastConfig', {
      message,
      title,
      variant
    });
  }
};
