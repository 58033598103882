import TripPlannerService from '~/common/services/trip-planner.service';

export const state = () => ({
  token: '',
  tripComponents: [],
  busToEdit: null,
  hotelToEdit: null,
  activityToEdit: null,
  restaurantToEdit: null,
});

export const mutations = {
  setToken (state, token) {
    state.token = token;
  },
  setTripComponents (state, components) {
    state.tripComponents = components;
  },
  setBusToEdit (state, bus) {
    state.busToEdit = bus;
  },
  setHotelToEdit (state, hotel) {
    state.hotelToEdit = hotel;
  },
  setActivityToEdit (state, activity) {
    state.activityToEdit = activity;
  },
  setRestaurantToEdit (state, restaurant) {
    state.restaurantToEdit = restaurant;
  },
};

export const actions = {
  setToken ({ commit }, token) {
    commit('setToken', token);
  },

  async fetchTripComponents ({ state, commit }, startDate) {
    const data = await TripPlannerService.fetchTripPlanners(
      this.$axios,
      state.token,
      startDate
    );
    commit('setTripComponents', data);
  },

  // ------------- HOTELS -------------

  async createExternalHotel ({ state }, payload) {
    return await TripPlannerService.createExternalHotel(
      this.$axios,
      state.token,
      payload
    );
  },
  async updateExternalHotel ({ state }, payload) {
    return await TripPlannerService.updateExternalHotel(
      this.$axios,
      state.token,
      payload
    );
  },
  async deleteExternalHotel ({ state }, { id }) {
    await TripPlannerService.deleteExternalHotel(this.$axios, state.token, id);
  },
  setHotelToEdit ({ commit }, hotel) {
    commit('setHotelToEdit', hotel);
  },

  // ------------- BUSES -------------

  async createExternalBus ({ state }, payload) {
    return await TripPlannerService.createExternalBus(
      this.$axios,
      state.token,
      payload
    );
  },
  async updateExternalBus ({ state }, payload) {
    return await TripPlannerService.updateExternalBus(
      this.$axios,
      state.token,
      payload
    );
  },
  async deleteExternalBus ({ state }, { id }) {
    await TripPlannerService.deleteExternalBus(this.$axios, state.token, id);
  },
  setBusToEdit ({ commit }, bus) {
    commit('setBusToEdit', bus);
  },

  // ------------- ACTIVITIES -------------

  async createExternalActivity ({ state }, payload) {
    return await TripPlannerService.createExternalActivity(
      this.$axios,
      state.token,
      payload
    );
  },
  async updateExternalActivity ({ state }, payload) {
    return await TripPlannerService.updateExternalActivity(
      this.$axios,
      state.token,
      payload
    );
  },
  async deleteExternalActivity ({ state }, { id }) {
    await TripPlannerService.deleteExternalActivity(
      this.$axios,
      state.token,
      id
    );
  },
  setActivityToEdit ({ commit }, activity) {
    commit('setActivityToEdit', activity);
  },

  // ------------- RESTAURANT -------------

  async createExternalRestaurant ({ state }, payload) {
    return await TripPlannerService.createExternalRestaurant(
      this.$axios,
      state.token,
      payload
    );
  },
  async updateExternalRestaurant ({ state }, payload) {
    return await TripPlannerService.updateExternalRestaurant(
      this.$axios,
      state.token,
      payload
    );
  },
  async deleteExternalRestaurant ({ state }, { id }) {
    await TripPlannerService.deleteExternalRestaurant(
      this.$axios,
      state.token,
      id
    );
  },
  setRestaurantToEdit ({ commit }, activity) {
    commit('setRestaurantToEdit', activity);
  },

  // ------------- GENERAL -------------

  async addUsersToExternalComponent (
    { state },
    { componentId, componentType, emailAddressesToUpdate }
  ) {
    await TripPlannerService.addUsersToExternalComponent(
      this.$axios,
      state.token,
      componentId,
      componentType,
      emailAddressesToUpdate
    );
  },
  async removeUsersFromExternalComponent (
    { state },
    { componentId, componentType, emailAddressesToUpdate }
  ) {
    await TripPlannerService.removeUsersFromExternalComponent(
      this.$axios,
      state.token,
      componentId,
      componentType,
      emailAddressesToUpdate
    );
  },

  async addFileToExternalComponent (
    { state },
    { componentId, componentType, name, file }
  ) {
    await TripPlannerService.addFileToExternalComponent(
      this.$axios,
      state.token,
      componentId,
      componentType,
      name,
      file
    );
  },

  async removeFileFromExternalComponent (
    { state },
    { componentId, componentType, fileId }
  ) {
    await TripPlannerService.removeFileFromExternalComponent(
      this.$axios,
      state.token,
      componentId,
      componentType,
      fileId
    );
  },
};

export const getters = {
  tripComponents: state => state.tripComponents,
  busToEdit: state => state.busToEdit,
  hotelToEdit: state => state.hotelToEdit,
  activityToEdit: state => state.activityToEdit,
  restaurantToEdit: state => state.restaurantToEdit,
};
