
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  components: {
    LanguageDropdown: () => import('~/components/header/LanguageDropdown'),
    Icon: () => import('~/common/shared-components/Icon.vue'),
  },
  props: {
    home: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getBusDestinations: 'destination/getBusDestinations',
      getHotelDestinations: 'destination/getHotelDestinations',
      nearMeBusDestinations: 'destination/getNearMeBusDestinations',
      nearMeHotelDestinations: 'destination/getNearMeHotelDestinations',
    }),
    hotelDeals () {
      const hotelDeals = [];
      if (this.nearMeHotelDestinations.length) {
        this.nearMeHotelDestinations.forEach((city) => {
          city.busDestinations.forEach((d) => {
            hotelDeals.push(d);
          });
        });
        return hotelDeals.filter(
          (hd, index, self) =>
            /**
             * remove duplicate cities
             */
            self.findIndex(v => v.code === hd.code) === index
        );
      } else {
        return hotelDeals;
      }
    },
  },
};
