export const SEARCH_TABS_EN = [
  {
    slug: 'bus',
    title: 'Bus',
    icon: 'bus-search'
  },
  // {
  //   slug: 'hotels',
  //   title: 'Hotels',
  //   icon: 'hotel-search'
  // },
  {
    slug: 'busAndHotels',
    title: 'Bus & Hotels',
    icon: 'busAndHotel'
  },
  // {
  //   slug: 'organizedTours',
  //   title: 'Organized Tours',
  //   icon: 'tour'
  // },
  // {
  //   slug: 'activities',
  //   title: 'Activities',
  //   icon: 'activity'
  // }
];
export const SEARCH_TABS_FR = [
  {
    slug: 'bus',
    title: 'Bus',
    icon: 'bus-search'
  },
  // {
  //   slug: 'hotels',
  //   title: 'Hôtels',
  //   icon: 'hotel-search'
  // },
  {
    slug: 'busAndHotels',
    title: 'Bus & Hôtels',
    icon: 'busAndHotel'
  },
  // {
  //   slug: 'organizedTours',
  //   title: 'Organized Tours',
  //   icon: 'tour'
  // },
  // {
  //   slug: 'activities',
  //   title: 'Activities',
  //   icon: 'activity'
  // }
];
