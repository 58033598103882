const middleware = {}

middleware['confirmation-guard'] = require('../middleware/confirmation-guard.js')
middleware['confirmation-guard'] = middleware['confirmation-guard'].default || middleware['confirmation-guard']

middleware['payment-guard'] = require('../middleware/payment-guard.js')
middleware['payment-guard'] = middleware['payment-guard'].default || middleware['payment-guard']

middleware['product-url-guard'] = require('../middleware/product-url-guard.js')
middleware['product-url-guard'] = middleware['product-url-guard'].default || middleware['product-url-guard']

middleware['trip-planner-guard'] = require('../middleware/trip-planner-guard.js')
middleware['trip-planner-guard'] = middleware['trip-planner-guard'].default || middleware['trip-planner-guard']

export default middleware
