export default function ({ $axios, redirect, $cookies, store, route, app }) {
  $axios.onRequest((config) => {
    config.headers = {
      sessionId: $cookies.get('sessionId') || route.query?.sessionId || '',
      'content-security-policy': "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'",
      'strict-transport-security': 'max-age=63072000',
      ...config.headers
    };
    config.params = {
      language: $cookies.get('lang') || 'en',
      ...config.params
    };

    // Remove custom headers and params for the urls with the strict cors policy
    const urlsWithStrictCorsPolicy = ['api.vbout.com', 'geocode/json', 'api.ipify.org', 'ipapi.co'];
    if (urlsWithStrictCorsPolicy.some(u => config.url.includes(u))) {
      config.headers = {};
      config.params = {};
    }

    // Setting authorization header to get user info
    if (config.url.includes('login.tripeze.com')) {
      config.headers = {
        authorization: $cookies.get('auth._token.auth0')
      };
      config.params = {};
    }
  });

  $axios.onError((e) => {
    if (e.response && e.response.status === 401) {
      // user will be automatically logged out if an error happens
      app.$auth.reset();
    }

    store.dispatch('alerts/showError', e?.response?.data?.message);
    store.dispatch('spinner/hideSpinner');
    return Promise.reject(e);
  });
}
