
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { seoInfo } from '@/common/mixins/seoInfo';
import { mapActions, mapGetters } from 'vuex';
import { QUOTES_EN, QUOTES_FR } from '~/common/constants';
import VboutService from '~/common/services/vbout.service';
import Header from '~/components/header/Header';

export default {
  name: 'without-footer',
  components: {
    Header,
  },
  mixins: [seoInfo],
  async fetch () {
    /**
     * Why do we fetch it here and not in the nuxtServerInit function?
     * If we follow the flow from the home page to the passengers without reloading,
     * nationalities won't be fetched because of "if (!route.name.includes('index'))";
     *
     * Fyi: we don't need f-ty inside nuxtServerInit on the home page
     */
    await this.fetchNationalities();
  },
  data: () => ({
    indexOfQuote: Math.floor(Math.random() * 10),
    message: '',
    isBookingInProgress: false,
  }),
  computed: {
    ...mapGetters({
      session: 'session/session',
      ipAddress: 'session/getIpAddress',
      isShowSpinner: 'spinner/isShow',
      toastConfig: 'systemMessage/toastConfig',
      googleJwtToken: 'authentication/googleJwtToken',
    }),
    lang () {
      return this.$i18n.locale;
    },
    quotes () {
      if (this.lang === 'en') {
        return [...QUOTES_EN];
      } else {
        return [...QUOTES_FR];
      }
    },
  },
  watch: {
    toastConfig () {
      if (this.toastConfig.length) {
        const current = this.toastConfig[0];
        this.$bvToast.toast(current.message, {
          solid: true,
          variant: current.variant,
          title: current.title,
        });
      }
    },
    isShowSpinner () {
      this.indexOfQuote = Math.floor(Math.random() * this.quotes.length);
    },
    session (updatedSession) {
      if (updatedSession) {
        this.isBookingInProgress = updatedSession.isBookingInProgress;
        this.message =
          updatedSession.bookingMessage?.charAt(0).toUpperCase() +
          updatedSession.bookingMessage?.replaceAll('-', ' ').slice(1);
      }
    },
  },
  created () {
    if (process.client) {
      if (this.$route.path.includes('fr')) {
        this.changeLanguage('fr');
      } else {
        this.changeLanguage('en');
      }
    }
    this.showSpinner();
  },
  methods: {
    ...mapActions({
      fetchNationalities: 'nationalities/fetchNationalities',
      changeLanguage: 'language/changeLanguage',
      showSpinner: 'spinner/showSpinner',
    }),
    async restoreAuth () {
      const token = this.$cookies.get('auth._token.auth0');
      if (!this.$auth.loggedIn && token) {
        // if we signed-in using google-one-tap
        const oldRedirect = this.$auth.options.redirect;
        this.$auth.options.redirect = false;
        await this.$auth.setUser(jwt_decode(token));
        this.$auth.options.rewriteRedirects = oldRedirect;
      }
      /**
       * send user's data to the Vbout
       */
      if (this.$auth.user) {
        VboutService.sendVboutPayload(this.$axios, {
          language: this.lang,
          email: this.$auth.user.email,
          given_name: this.$auth.user.given_name,
          family_name: this.$auth.user.family_name,
          ipAddress: this.ipAddress,
        });
      }
    },
  },
  head () {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.tripeze.com${this.$route.path}`,
        },
      ],
    };
  },
};
