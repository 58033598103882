export const state = () => ({
  currencyCode: 'CAD',
  currencySymbols: {
    EUR: '€',
    CAD: 'CA $',
    USD: '$'
  }
});

export const mutations = {
  changeCurrency (state, currencyCode) {
    state.currencyCode = currencyCode;
  }
};

export const actions = {
  async changeCurrency ({ commit, dispatch, rootGetters }, currencyCode) {
    commit('changeCurrency', currencyCode);
    const currentSession = rootGetters['session/session'];
    if (currentSession.sessionId && !currentSession.isLocked) {
      await dispatch('session/changeCurrency', currencyCode, { root: true });
    }
  }
};

export const getters = {
  currencyCode: state => state.currencyCode,
  currencySymbol: state => state.currencySymbols[state.currencyCode]
};
