export default class TripPlannerService {
  /**
   * Get trip planner list
   */
  static fetchTripPlanners (
    axios,
    token,
    startDate
  ) {
    return axios.$get('/v1/trip-planners', {
      params: { startDate },
      headers: {
        token,
      },
    });
  }

  // ------------- HOTELS -------------

  /**
   * Create an external hotel of trip planner
   */
  static createExternalHotel (axios, token, payload) {
    return axios.$post('/v1/trip-planners/external-hotels', payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Update an external hotel of trip planner
   */
  static updateExternalHotel (axios, token, hotel) {
    const payload = { ...hotel };
    const url = `/v1/trip-planners/external-hotels/${payload.id}`;
    delete payload.id;

    return axios.$put(url, payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Delete an external hotel of trip planner
   */
  static deleteExternalHotel (axios, token, id) {
    return axios.delete(`/v1/trip-planners/external-hotels/${id}`, {
      headers: {
        token,
      },
    });
  }

  // ------------- BUSES -------------

  /**
   * Create an external bus of trip planner
   */
  static createExternalBus (axios, token, payload) {
    return axios.$post('/v1/trip-planners/external-buses', payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Update an external bus of trip planner
   */
  static updateExternalBus (axios, token, bus) {
    const payload = { ...bus };
    const url = `/v1/trip-planners/external-buses/${payload.id}`;
    delete payload.id;

    return axios.$put(url, payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Delete an external bus of trip planner
   */
  static deleteExternalBus (axios, token, id) {
    return axios.delete(`/v1/trip-planners/external-buses/${id}`, {
      headers: {
        token,
      },
    });
  }

  // ------------- ACTIVITIES -------------

  /**
   * Create an external activity of trip planner
   */
  static createExternalActivity (axios, token, payload) {
    return axios.$post('/v1/trip-planners/external-activities', payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Update an external activity of trip planner
   */
  static updateExternalActivity (axios, token, activity) {
    const payload = { ...activity };
    const url = `/v1/trip-planners/external-activities/${payload.id}`;
    delete payload.id;

    return axios.$put(url, payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Delete an external activity of trip planner
   */
  static deleteExternalActivity (axios, token, id) {
    return axios.delete(`/v1/trip-planners/external-activities/${id}`, {
      headers: {
        token,
      },
    });
  }

  // ------------- RESTAURANTS -------------

  /**
   * Create an external restaurant of trip planner
   */
  static createExternalRestaurant (axios, token, payload) {
    return axios.$post('/v1/trip-planners/external-restaurants', payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Update an external restaurant of trip planner
   */
  static updateExternalRestaurant (axios, token, restaurant) {
    const payload = { ...restaurant };
    const url = `/v1/trip-planners/external-restaurants/${payload.id}`;
    delete payload.id;

    return axios.$put(url, payload, {
      headers: {
        token,
      },
    });
  }

  /**
   * Delete an external restaurant of trip planner
   */
  static deleteExternalRestaurant (axios, token, id) {
    return axios.delete(`/v1/trip-planners/external-restaurants/${id}`, {
      headers: {
        token,
      },
    });
  }
  // ------------- GENERAL -------------

  /**
   * Add users to this external component of trip planner
   */
  static addUsersToExternalComponent (
    axios,
    token,
    componentId,
    componentType,
    emailAddressesToUpdate
  ) {
    return axios.patch(
      `/v1/trip-planners/external-components/${componentId}/add-users`,
      {
        componentType,
        emailAddressesToUpdate,
      },
      {
        headers: {
          token,
        },
      }
    );
  }

  /**
   * Remove users from this external component of trip planner
   */
  static removeUsersFromExternalComponent (
    axios,
    token,
    componentId,
    componentType,
    emailAddressesToUpdate
  ) {
    return axios.patch(
      `/v1/trip-planners/external-components/${componentId}/remove-users`,
      {
        componentType,
        emailAddressesToUpdate,
      },
      {
        headers: {
          token,
        },
      }
    );
  }

  /**
   * Add file to this external component of trip planner
   */
  static addFileToExternalComponent (
    axios,
    token,
    componentId,
    componentType,
    name,
    file
  ) {
    return axios.patch(
      `/v1/trip-planners/external-components/${componentId}/add-file`,
      {
        componentType,
        name,
        file,
      },
      {
        headers: {
          token,
        },
      }
    );
  }

  /**
   * Remove file to this external component of trip planner
   */
  static removeFileFromExternalComponent (
    axios,
    token,
    componentId,
    componentType,
    fileId
  ) {
    return axios.patch(
      `/v1/trip-planners/external-components/${componentId}/remove-file/${fileId}`,
      {
        componentType,
      },
      {
        headers: {
          token,
        },
      }
    );
  }
}
